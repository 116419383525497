import React, { useState, useEffect, useRef } from "react";
import { useWeb3 } from "../Web3Context";
import Web3 from "web3";
import tokenSwapABI from "../abis/TokenSwapABI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "@emotion/styled";

import LoadingDots from "./LoadingDots";
import pancakelogo from "../pancakelogo.svg";
import SETTINGS from "../settings";
import ERC20TokenABI from "../abis/ERC20TokenABI.json";
import metamask from "../metamask.svg";
import InfoModal from "./InfoModal";
import {
  faCreditCard,
  faShuffle,
  faSpinner,
  faBasketShoppingPlus,
  faBolt,
  faSpinnerThird,
} from "@fortawesome/pro-thin-svg-icons";

const SpinnerBox = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: var(--maincolor);
  font-weight: bold;
  font-size: 15px;
  border: 0px solid var(--maincolor);
  padding: 10px;
  border-radius:100px;
  cursor: pointer;
  width:100%;
  transition: all 0.2s;
  text-align:center;
`;

const Wallet = ({ showBridgeFunction }) => {
  const { web3, selectedAccount, networkId, networkSymbol, networkIcons } =
    useWeb3();
  const [nativeSymbol, setNativeSymbol] = useState(null);
  const [nativeIcon, setNativeIcon] = useState(null);
  const [balance, setBalance] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [buyTokens, setBuyTokens] = useState([]);
  const [waweTokens, setWaweTokens] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBalance = async () => {
    if (!Web3.utils.isAddress(selectedAccount)) {
      //alert("Please enter a valid Ethereum address.");
      return;
    }

    try {
      const balanceWei = await web3.eth.getBalance(selectedAccount);
      const balanceEth = web3.utils.fromWei(balanceWei, "ether");
      setBalance(parseFloat(balanceEth).toFixed(4));
      setNativeSymbol(networkSymbol);

      let imgLink = getTokenIcon(networkSymbol);
      setNativeIcon(imgLink);
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  const loadAvailableTokensPolygon = async () => {
    setLoading(false); // Update loading status when tokens are already loaded
    return;
  };

  const loadAvailableTokens = async () => {
    setLoading(false); // Update loading status when tokens are already loaded
    return;
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getTokenIcon = (symbol) => {
    return SETTINGS.tokenIcons[symbol];
  };

  const openExchange = (addr) => {
    const url = SETTINGS.dexLinks[networkId];
    window.open(url + addr, "_blank").focus();
  };

  const openTokenListingForm = () => {
    window
      .open(
        "https://docs.google.com/forms/d/e/1FAIpQLScZn0ZYcBuoAa-DZBIjY0Oj38y-co2og7JQ93lBjcxy-jnLEw/viewform",
        "_blank"
      )
      .focus();
  };
  const addToMetamask = async (addr) => {
    const tokenContract = new web3.eth.Contract(ERC20TokenABI, addr);

    try {
      const symbol = await tokenContract.methods.symbol().call();
      const decimals = await tokenContract.methods.decimals().call();
      const image = SETTINGS.tokenIcons[symbol];

      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: addr,
            symbol: symbol,
            decimals: decimals,
            image: image,
          },
        },
      });
    } catch {}
  };

  const openMetamaskBuy = () => {
    window
      .open("https://portfolio.metamask.io/buy/build-quote", "_blank")
      .focus();
  };

  const openDao = () => {
    window.open("https://snapshot.org/#/waweswaps.eth", "_blank").focus();
  };

  const loadBalances = async () => {
    if (networkId == 56) {
      await loadAvailableTokens();
    } else {
      await loadAvailableTokensPolygon();
    }
  };

  useEffect(() => {
    if (selectedAccount && networkId) {
      //getSavedBalances(selectedAccount);
      setLoading(true);
      setTokens([]);
      setBuyTokens([]);
      getBalance();
      loadBalances();
    }
  }, [selectedAccount, networkId]);

  return (
    <>
      {loading ? (
        <center>
          <FontAwesomeIcon
            icon={faSpinnerThird}
            spin
            color="var(--maincolor)"
            className="bigLoader"
          />

          <p className="smallTitle">
            <br /> Loading
            <LoadingDots />
          </p>
          <br />
          <br />
          <br />
          <br />
        </center>
      ) : (
        <div className="waweSwap">
        
          <div className="row">
            <div className="col-12" style={{ textAlign: "left" }}>
              <div style={{ textAlign: "left" }}>
                <h5
                  className=""
                  style={{ marginBottom: 10, display: "block", marginTop:"20px", color:"var(--maincolor)" }}
                >
                  Bridge GBL
                  <InfoModal link="https://docs.waveswaps.com/products-and-features/bridge-gbl" message="Bridge GBL is a vital tool facilitating seamless GBL token transfers across various blockchain networks like BNB Smart Chain, Polygon, Arbitrum, and Optimism. Bridging liquidity is essential for smooth order execution and interoperability, ensuring users can navigate GBL tokens across different decentralized exchange markets. It's crucial to note that GBL bridge requests are subject to specific conditions and executed entirely to maintain secure token transfers. Additionally, WaveSwaps may suggest third-party token bridge providers to enhance token interoperability further." />
    
                </h5>
                {networkId != 56 && (
                  <button
                    className="containerMenuBtnBridge"
                    onClick={() => {
                      showBridgeFunction(56);
                    }}
                  >
                    <img src={networkIcons[networkId]} className="iconPrice" />
                    <FontAwesomeIcon icon={faShuffle} />
                    <img
                      src="https://docs.waweswaps.com/images/chains/smartchain.svg"
                      className="iconPrice"
                    />
                  </button>
                )}
                {networkId != 137 && (
                  <button
                    className="containerMenuBtnBridge"
                    onClick={() => {
                      showBridgeFunction(137);
                    }}
                  >
                    <img src={networkIcons[networkId]} className="iconPrice" />
                    <FontAwesomeIcon icon={faShuffle} />
                    <img
                      src="https://docs.waweswaps.com/images/chains/polygon.svg"
                      className="iconPrice"
                    />
                  </button>
                )}
                {networkId != 42161 && (
                  <button
                    className="containerMenuBtnBridge"
                    onClick={() => {
                      showBridgeFunction(42161);
                    }}
                  >
                    <img src={networkIcons[networkId]} className="iconPrice" />
                    <FontAwesomeIcon icon={faShuffle} />
                    <img
                      src="https://docs.waweswaps.com/images/chains/arbitrum.svg"
                      className="iconPrice"
                    />
                  </button>
                )}
                {networkId != 10 && (
                  <button
                    className="containerMenuBtnBridge"
                    onClick={() => {
                      showBridgeFunction(10);
                    }}
                  >
                    <img src={networkIcons[networkId]} className="iconPrice" />
                    <FontAwesomeIcon icon={faShuffle} />
                    <img
                      src="https://docs.waweswaps.com/images/chains/optimism.svg"
                      className="iconPrice"
                    />
                  </button>
                )}
          
              </div>
            </div>
            {/* 
            {waweTokens.map((token, index) => (
              <div className="col-12" key={index}>
                <div className="card walletCard text-center">
                  <div className="card-body walletCardBody">
                    <div className="row">
                      <div className="col-2">
                        <img src={token.imgLink} className="walletIcon" />
                      </div>
                      <div className="col-2">
                        <small className="smallText">{token.symbol}</small>
                      </div>
                      <div className="col-4" style={{ textAlign: "right" }}>
                        {loading ? (
                          <center>
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin
                              color="var(--maincolor)"
                              className="smallSpinner marginRight"
                            />
                          </center>
                        ) : null}
                        <small>{token.balanceOf}</small>
                        <small className="smallText">
                          .{token.balanceOfDecimals}
                        </small>
                      </div>

                      <div className="col-4">
                        <center>
                          {token.address == SETTINGS.voteGlobalTokenAddress ? (
                            <button className="buyButton" onClick={openDao}>
                              <small>
                                <FontAwesomeIcon
                                  icon={faBolt}
                                  style={{ marginRight: "5px" }}
                                />
                              </small>
                              Vote
                            </button>
                          ) : (
                            <button
                              className="buyButton"
                              onClick={() => openExchange(token.address)}
                            >
                              <small>
                                <FontAwesomeIcon
                                  icon={faBasketShoppingPlus}
                                  style={{ marginRight: "5px" }}
                                />
                              </small>
                              Buy
                            </button>
                          )}

                          <button
                            className="buyButton"
                            onClick={() => addToMetamask(token.address)}
                          >
                            <small>
                              <img src={metamask} width={12} />
                            </small>
                          </button>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
          {/* 
          <div className="row">
            <div
              className="col-12"
              style={{ textAlign: "left", marginTop: 15 }}
            >
              <small className="smallTitle">Swapping tokens</small>
            </div>
            <div className="col-12">
              <div className="card walletCard text-center">
                <div className="card-body walletCardBody">
                  <div className="row">
                    <div className="col-2">
                      <img
                        width="25px"
                        className="walletIcon"
                        src={nativeIcon}
                      />
                    </div>
                    <div className="col-2">
                      <small className="smallText">{nativeSymbol}</small>
                    </div>
                    <div className="col-4" style={{ textAlign: "right" }}>
                      <small>{balance}</small>
                    </div>
                    <div className="col-4">
                      <center>
                        <button
                          className="buyButton"
                          onClick={() => openMetamaskBuy()}
                        >
                          <small>
                            <FontAwesomeIcon
                              icon={faCreditCard}
                              style={{ marginRight: "5px" }}
                            />
                          </small>
                          Buy
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {buyTokens.map((token, index) => (
              <div className="col-12" key={index}>
                <div className="card walletCard text-center ">
                  <div className="card-body walletCardBody">
                    <div className="row">
                      <div className="col-2">
                        <img
                          width="25px"
                          src={token.imgLink}
                          className="walletIcon"
                        />
                      </div>
                      <div className="col-2">
                        <small className="smallText">{token.symbol}</small>
                      </div>
                      <div className="col-4" style={{ textAlign: "right" }}>
                        {loading ? (
                          <center>
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin
                              color="var(--maincolor)"
                              className="smallSpinner marginRight"
                            />
                          </center>
                        ) : null}
                        <small>{token.balanceOf}</small>
                        <small className="smallText">
                          .{token.balanceOfDecimals}
                        </small>
                      </div>

                      <div className="col-4">
                        <center>
                          <button
                            className="buyButton"
                            onClick={() => openExchange(token.address)}
                          >
                            <small>
                              <FontAwesomeIcon
                                icon={faBasketShoppingPlus}
                                style={{ marginRight: "5px" }}
                              />
                            </small>
                            Buy
                          </button>
                          <button
                            className="buyButton"
                            onClick={() => addToMetamask(token.address)}
                          >
                            <small>
                              <img src={metamask} width={12} />
                            </small>
                          </button>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {networkId == 56 ? (
            <div className="row">
              <div
                className="col-12"
                style={{ textAlign: "left", marginTop: 15 }}
              >
                <small className="smallTitle">Recycle tokens</small>
              </div>
              {tokens.map((token, index) => (
                <div className="col-12 " key={index}>
                  <div className="card walletCard text-center">
                    <div className="card-body walletCardBody">
                      <div className="row">
                        <div className="col-2">
                          <img
                            width="25px"
                            src={token.imgLink}
                            className="walletIcon"
                          />
                        </div>
                        <div className="col-2">
                          <small className="smallText">{token.symbol}</small>
                        </div>
                        <div className="col-4" style={{ textAlign: "right" }}>
                          {loading ? (
                            <center>
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                color="var(--maincolor)"
                                className="smallSpinner marginRight"
                              />
                            </center>
                          ) : null}
                          <small>{token.balanceOf}</small>
                          <small className="smallText">
                            .{token.balanceOfDecimals}
                          </small>
                        </div>

                        <div className="col-4">
                          <center>
                            <button
                              className="buyButton"
                              onClick={() => openExchange(token.address)}
                            >
                              <small>
                                <FontAwesomeIcon
                                  icon={faBasketShoppingPlus}
                                  style={{ marginRight: "5px" }}
                                />
                              </small>
                              Buy
                            </button>
                            <button
                              className="buyButton"
                              onClick={() => addToMetamask(token.address)}
                            >
                              <small>
                                <img src={metamask} width={12} />
                              </small>
                            </button>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className="col-12"
                style={{ textAlign: "left", marginTop: 15 }}
              >
                <small
                  className="smallTitle link"
                  onClick={openTokenListingForm}
                >
                  Request token listing
                </small>
              </div>
            </div>
          ) : null}
          */}
        </div>
      )}
    </>
  );
};

export default Wallet;
