import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/pro-thin-svg-icons";

const TooltipInfoReverseGbl = ({cur = "GBL", title = "Revamp income:"}) => {
  const tooltip = (
    <Tooltip id="tooltip" style={{width:350, display:"block"}}><small className='smallText'>
    <b>{title}</b>


  <ul style={{textAlign:"left"}}>
<li>1 to 9 {cur}: 100%</li>
<li>10 to 19 {cur}: 90%</li>
<li>20 to 29 {cur}: 80%</li>
<li>30 to 39 {cur}: 70%</li>
<li>40 to 49 {cur}: 60%</li>
<li>50 {cur} & more: 50%</li>


</ul>

    </small></Tooltip>
  );

  return (
    <OverlayTrigger overlay={tooltip} placement="top">
      <button className="tooltipButton" >
                        <small><FontAwesomeIcon icon={faCircleInfo} style={{marginRight:"0px"}} /></small>
                          </button>
    </OverlayTrigger>
  );
};

export default TooltipInfoReverseGbl;