import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { useWeb3 } from "../Web3Context"; // Make sure you have this context setup
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import WaweSwapsABI from "../abis/WaweSwapsV2ABI";
import WaweSwapStorageABI from "../abis/WaweSwapStorageV2ABI";
import SETTINGS from "../settings";
import styled from "@emotion/styled";
const ClaimButton = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: #999999;
  font-weight: bold;
  font-size: 10px;
  border: 1px solid #999999;
  padding: 5px 20px;
  border-radius:100px;
  cursor: pointer;
  margin-left:0px;
 margin-top:12px;
  transition: all 0.2s;
  text-align:center;
  &:hover {
    
    border: 1px solid var(--maincolor);
    color: var(--maincolor);
    transition: all 0.6s;
  }
`;

const AdminPanelV2 = () => {
  const { web3, selectedAccount } = useWeb3(); // Assuming you have web3 context
  const [contract, setContract] = useState(null);
  const [fee, setFee] = useState('');
  
  const [showAdminPanel, setShowAdminPanel] = useState(false);

  const [liquidityPercent, setLiquidityPercent] = useState('');
  const [gblBuyPercent, setGblBuyPercent] = useState('');
  const [recycleBuyPercent, setRecycleBuyPercent] = useState('');
  const [referralPercent, setReferralPercent] = useState('');
  const [timestampPercent, setTimestampPercent] = useState('');
  const [distributePercent, setDistributePercent] = useState('');
  const [usdtRewardsToDistribute, setUsdtRewardsToDistribute] = useState('');
  const [slippageTolerance, setSlippageTolerance] = useState('');

  useEffect(() => {
    if (web3 && selectedAccount) {
      const contractInstance = new web3.eth.Contract(WaweSwapsABI, SETTINGS.waweSwapAddress);
      setContract(contractInstance);
      loadContractSettings(contractInstance);
    }
  }, [web3, selectedAccount]);

  useEffect(() => {
    const loadInitialSettings = async () => {
      if (web3 && selectedAccount && contract) {
        // Fetching fee
        const fetchedFee = await contract.methods.FEE().call();
        setFee(web3.utils.fromWei(fetchedFee, 'ether')); // Convert from Wei if necessary
        
        // Fetching auto distribute status
       
        
        // Fetching distribution percentages
        const fetchedLiquidityPercent = await contract.methods.LIQUIDITY_PERCENT().call();
        setLiquidityPercent(fetchedLiquidityPercent);
  
        const fetchedGblBuyPercent = await contract.methods.GBL_BUY_PERCENT().call();
        setGblBuyPercent(fetchedGblBuyPercent);
  
        const fetchedRecycleBuyPercent = await contract.methods.RECYCLE_BUY_PERCENT().call();
        setRecycleBuyPercent(fetchedRecycleBuyPercent);
  
        const fetchedReferralPercent = await contract.methods.REFFERAL_PERCENT().call();
        setReferralPercent(fetchedReferralPercent);
  
        const fetchedTimestampPercent = await contract.methods.TIMESTAMP_PERCENT().call();
        setTimestampPercent(fetchedTimestampPercent);
  
        const fetchedDistributePercent = await contract.methods.DISTRIBUTE_PERCENT().call();
        setDistributePercent(fetchedDistributePercent);
  
        // Fetch and set other contract settings as needed
      }
    };
  
    if (contract) {
      loadInitialSettings().catch(console.error);
    }
  }, [web3, selectedAccount, contract]);
  

  const loadContractSettings = async (contractInstance) => {
    const fee = await contractInstance.methods.FEE().call();
    const autoDistribute = await contractInstance.methods.AUTO_DISTRIBUTE().call();
    const liquidityPercent = await contractInstance.methods.LIQUIDITY_PERCENT().call();
    // Load other settings similarly
    setFee(fee);
   
    setLiquidityPercent(liquidityPercent);
    // Set other states similarly
  };


  const updateFee = async () => {
    if (!contract) return;
    try {
      await contract.methods.changeFEE(web3.utils.toWei(fee, 'ether')).send({ from: selectedAccount });
      alert("Fee updated successfully!");
    } catch (error) {
      console.error("Error updating fee:", error);
      alert("Failed to update fee.");
    }
  };

  
  // Update Distribution Settings
  // Assuming you have similar state hooks for other percentages as you do for `liquidityPercent`
  const updateDistributionSettings = async () => {
    if (!contract) return;
    try {
      await contract.methods.updateDistributePercent(
        liquidityPercent, gblBuyPercent, recycleBuyPercent, referralPercent, timestampPercent, distributePercent
      ).send({ from: selectedAccount });
      alert("Distribution settings updated successfully!");
    } catch (error) {
      console.error("Error updating distribution settings:", error);
      alert("Failed to update distribution settings.");
    }
  };
  
  // Distribute USDT to Buy GBL
  const distributeUsdtToBuyGbl = async () => {
    if (!contract) return;
    try {
      await contract.methods.distributeUsdtToBuyGbl().send({ from: selectedAccount });
      alert("Distributed USDT to buy GBL successfully!");
    } catch (error) {
      console.error("Error distributing USDT to buy GBL:", error);
      alert("Failed to distribute USDT to buy GBL.");
    }
  };
  
  // Collect All Fees
  const collectAllFees = async () => {
    if (!contract) return;
    try {
      await contract.methods.collectAllFees().send({ from: selectedAccount });
      alert("All fees collected successfully!");
    } catch (error) {
      console.error("Error collecting fees:", error);
      alert("Failed to collect fees.");
    }
  };
  
  // Transfer All ERC Tokens
  const transferAllErcTokens = async () => {
    if (!contract) return;
    // This function likely needs a recipient address parameter
    try {
      await contract.methods.transferAllErcTokens(/* recipient address here */).send({ from: selectedAccount });
      alert("All ERC tokens transferred successfully!");
    } catch (error) {
      console.error("Error transferring all ERC tokens:", error);
      alert("Failed to transfer all ERC tokens.");
    }
  };
  
  
  
  

  // Add similar functions for updating other contract settings

  return (
    <div>
       <ClaimButton onClick={(e) => setShowAdminPanel(!showAdminPanel)}>Show admin panel</ClaimButton>
      {showAdminPanel ?(
     <Form>
     {/* Fee Update */}
     <div className='formAdminBlock'>
       <label>Fee</label>
       <input
         type="number"
         className="form-control-small"
         placeholder="Enter new fee"
         value={fee}
         onChange={(e) => setFee(e.target.value)}
       />
       <ClaimButton onClick={updateFee}>Update Fee</ClaimButton>
     </div>
 
   
   {/* GBL Buy Percent Update */}
<div className='formAdminBlock'>
  <label>GBL Buy Percent</label>
  <input
    type="number"
    className="form-control-small"
    placeholder="Enter new GBL Buy Percent"
    value={gblBuyPercent}
    onChange={(e) => setGblBuyPercent(e.target.value)}
  />
</div>

{/* Recycle Buy Percent Update */}
<div className='formAdminBlock'>
  <label>Recycle Buy Percent</label>
  <input
    type="number"
    className="form-control-small"
    placeholder="Enter new Recycle Buy Percent"
    value={recycleBuyPercent}
    onChange={(e) => setRecycleBuyPercent(e.target.value)}
  />
</div>

{/* Referral Percent Update */}
<div className='formAdminBlock'>
  <label>Referral Percent</label>
  <input
    type="number"
    className="form-control-small"
    placeholder="Enter new Referral Percent"
    value={referralPercent}
    onChange={(e) => setReferralPercent(e.target.value)}
  />
</div>

{/* Timestamp Percent Update */}
<div className='formAdminBlock'>
  <label>Timestamp Percent</label>
  <input
    type="number"
    className="form-control-small"
    placeholder="Enter new Timestamp Percent"
    value={timestampPercent}
    onChange={(e) => setTimestampPercent(e.target.value)}
  />
</div>

{/* Distribute Percent Update */}
<div className='formAdminBlock'>
  <label>Distribute Percent</label>
  <input
    type="number"
    className="form-control-small"
    placeholder="Enter new Distribute Percent"
    value={distributePercent}
    onChange={(e) => setDistributePercent(e.target.value)}
  />
</div>

<ClaimButton onClick={updateDistributionSettings}>Update Distribution Settings</ClaimButton>
   
     {/* Distribute USDT to Buy GBL */}
     <div className='formAdminBlock'>
       <ClaimButton onClick={distributeUsdtToBuyGbl}>Distribute USDT to Buy GBL</ClaimButton>
     </div>
   
     {/* Add Swap Admin Batch */}
     {/* Note: For complex inputs like arrays, consider a modal or separate form section to collect and submit data */}
     <div className='formAdminBlock'>
       <ClaimButton onClick={() => {}}>Add Swap Admin Batch (Implement)</ClaimButton>
     </div>
   
     {/* Add USDT Rewards Admin Batch */}
     <div className='formAdminBlock'>
       <ClaimButton onClick={() => {}}>Add USDT Rewards Admin Batch (Implement)</ClaimButton>
     </div>
   
     {/* Collect All Fees */}
     <div className='formAdminBlock'>
       <ClaimButton onClick={collectAllFees}>Collect All Fees</ClaimButton>
     </div>
   
     {/* Transfer All ERC Tokens */}
     <div className='formAdminBlock'>
       <ClaimButton onClick={transferAllErcTokens}>Transfer All ERC Tokens</ClaimButton>
     </div>
   
     {/* Transfer Specific ERC Tokens */}
     {/* This will need additional UI elements to specify the token and amount */}
     <div className='formAdminBlock'>
       <ClaimButton onClick={() => {}}>Transfer Specific ERC Tokens (Implement)</ClaimButton>
     </div>
   
     {/* Transfer Funds */}
     {/* Requires UI for specifying receiver address and amount */}
     <div className='formAdminBlock'>
       <ClaimButton onClick={() => {}}>Transfer Funds (Implement)</ClaimButton>
     </div>
   </Form>
   
      ):(<></>)}
    </div>
  );
};

export default AdminPanelV2;

