import React, { useState, useEffect } from "react";
import { useWeb3 } from "../Web3Context";
import Web3 from "web3";
import { ERC20_ABI } from "../abis/erc20";
import axios from "axios";
import SETTINGS from "../settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {faShuffle} from "@fortawesome/free-solid-svg-icons";
import Marquee from "react-fast-marquee";

// Add your API base URL here
const API_BASE_URL = SETTINGS.backendUrl;


const NetworkPrices = ({showBridgeFunction}) => {
    const { web3, selectedAccount, networkId } = useWeb3();
    const [price1, setPrice1] = useState("-");
    const [price2, setPrice2] = useState("-");
    const [price3, setPrice3] = useState("-");
    const [price4, setPrice4] = useState("-");
    const [price5, setPrice5] = useState("-");


  const getBalanceGbl = async (address) => {

    const tokenContractGbl = new web3.eth.Contract(ERC20_ABI, SETTINGS.globalTokenAddress);
   
    try {
      const balance = await tokenContractGbl.methods
        .balanceOf(address)
        .call();
      const decimals = await tokenContractGbl.methods.decimals().call();
      const formattedBalance = balance / 10 ** decimals;
      return numberWithCommas(formattedBalance.toFixed(2));
    } catch (error) {
      console.error("Error fetching token balance:", error);
    }
    return 0;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const fetchBridges = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/prices`);
      setPrice1(response.data[0].smartchain);
      setPrice2((parseFloat(response.data[0].polygon) / 1000000).toFixed(2));
      setPrice3((parseFloat(response.data[0].arbitrum) / 1000000).toFixed(2));
      setPrice4((parseFloat(response.data[0].optimism) / 1000000).toFixed(2));
      setPrice5((parseFloat(response.data[0].ethereum) / 1000000).toFixed(2));
    } catch (error) {
      console.error("Error fetching bridges:", error);
    }
  };
  

  const getBalances = async () => {
    await fetchBridges();
   
  };

  useEffect(() => {
    getBalances();
  }, [selectedAccount]);

  return (
    <div className="halvingBalancesContainer">
       <Marquee className="movingDisplay" pauseOnHover pauseOnClick>
            <div className="rewardsBox" style={{marginRight:150}}>
                
            
                <img src="https://docs.waweswaps.com/images/chains/smartchain.svg" className="iconPrice" />
                <small className="smallTitleSmall ">BNB Smart Chain: </small>
                <small className="smallText ">GBL price:</small>
                <h6> 1 GBL / <small className="smallTitleSmall ">{price1}</small> USDT</h6>
               {/*  <small className="smallText ">SwapV3 pool:</small>
                <h6> 0 GBL</h6>*/}
                
                
              </div>
              <div className="rewardsBox" style={{marginRight:150}}>
                
                 
                <img src="https://docs.waweswaps.com/images/chains/polygon.svg" className="iconPrice" />
                <small className="smallTitleSmall ">Polygon: </small>
                <small className="smallText ">GBL price:</small>
                <h6> 1 GBL / <small className="smallTitleSmall ">{price2}</small> USDT</h6>
                 {/* <small className="smallText ">SwapV3 pool:</small>
                <h6> 4,475,000 GBL</h6>*/}
                
                
              </div>
              <div className="rewardsBox" style={{marginRight:150}}>
                
                 
                <img src="https://docs.waweswaps.com/images/chains/arbitrum.svg" className="iconPrice" />
                <small className="smallTitleSmall ">Arbitrum: </small>
                <small className="smallText ">GBL price:</small>
                <h6> 1 GBL / <small className="smallTitleSmall ">{price3}</small> USDT</h6>
                {/*<small className="smallText ">SwapV3 pool:</small>
                <h6> 2,237,500 GBL</h6>*/}
               
                
              </div>
              <div className="rewardsBox" style={{marginRight:150}}>
                
                 
                <img src="https://docs.waweswaps.com/images/chains/optimism.svg" className="iconPrice" />
                <small className="smallTitleSmall ">Optimism: </small>
                <small className="smallText ">GBL price:</small>
                <h6> 1 GBL / <small className="smallTitleSmall ">{price4}</small> USDT</h6>
               {/* <small className="smallText ">SwapV3 pool:</small>
                <h6> 1,118,750 GBL</h6>*/}
            
                
              </div>
     
     
              



    </Marquee>

    </div>
  );
};

export default NetworkPrices;
