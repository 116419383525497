import React from 'react';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Col, Row, Table, Button } from 'react-bootstrap';
import SETTINGS from "../settings";
const SortingTable = ({dataRows, claimFunction}) => {


    // Create table headers consisting of 4 columns.
const headers = [



  { title: 'Offering', prop: 'amount_in', isSortable: true},
  { title: '', prop: 'receive',
  cell: (row) => (
    <small> 
      <img src={SETTINGS.networkIcons[row.from_network]} className="iconPrice" style={{width:15}} />
      <img src={SETTINGS.networkIcons[row.to_network]} className="iconPrice" style={{width:15}}  />
      </small>
  ) },
  { title: 'Asking', prop: 'amount_out', isSortable: true},
  
  {
    prop: "button",
    cell: (row) => (
      <button className="buyButton" 
        onClick={() => {
          claimFunction(row.id, row.amount_out, row.amount_in);
        }}
      >
        Bridge
      </button>
    )
  }
];


    return (
      <DatatableWrapper body={dataRows} headers={headers}
    
      paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 15, 20]
        }
      }}>
    
      <Table bordered>
        <TableHeader />
        <TableBody />
      </Table>
      <br />
      <Row className="mb-4">
  

  <Col
    xs={12}
    sm={12}
    lg={12}
    className="d-flex flex-col justify-content-center align-items-center"
  >
    <Pagination />
  </Col>
  
</Row>
    </DatatableWrapper>
    );
}

export default SortingTable;




