import React, { useState, useEffect, useRef } from "react";
import { useWeb3 } from "../Web3Context";
import Web3 from "web3";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const FloatingMenu = ({showWalletFunction}) => {
    const { web3, selectedAccount, connectMetaMask, changeNetwork, networkId } = useWeb3();
  const [isOpen, setIsOpen] = useState(false);
  const [networkIcon, setNetworkIcon] = useState("");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const switchNetwork = async (id) => {
    await changeNetwork(id);
    await connectMetaMask();
    toggleMenu();
    showWalletFunction();
  };

  const setIcon = () => {
    let iconUrl = "";
    if(networkId == 1) iconUrl = "https://docs.waweswaps.com/images/chains/ethereum.svg";
    if(networkId == 10) iconUrl = "https://docs.waweswaps.com/images/chains/optimism.svg";
    if(networkId == 137) iconUrl = "https://docs.waweswaps.com/images/chains/polygon.svg";
    if(networkId == 42161) iconUrl = "https://docs.waweswaps.com/images/chains/arbitrum.svg";
    if(networkId == 56) iconUrl = "https://docs.waweswaps.com/images/chains/smartchain.svg";
    setNetworkIcon(iconUrl);
  };


  useEffect(() => {
    if (selectedAccount) {
        setIcon();
    }
  }, [selectedAccount, networkId]);


  return (
    <>

    <div style={{ position: 'absolute', top: '7px', left: '12px', zIndex: 1000 }}>
      <button className="btn-network" onClick={toggleMenu}>
      <img src={networkIcon} className="networkIcon" />
      </button>
      {isOpen && (
        <div className="card" style={{ position: 'absolute', top: '30px', width: '300px' }}>
          <ul className="list-group list-group-flush" style={{ borderRadius: '20px' }}>
            <li className="list-group-item" >
            
                <b><small>Switch to:</small></b>
                </li>
          
     
            <li className="list-group-item" onClick={() => switchNetwork(56)}  style={{ cursor: 'pointer' }}>
            <img src="https://docs.waweswaps.com/images/chains/smartchain.svg" className="networkIcon" />
                BNB Smart Chain</li>
                <li className="list-group-item" onClick={() => switchNetwork(137)} style={{ cursor: 'pointer' }}>
            <img src="https://docs.waweswaps.com/images/chains/polygon.svg" className="networkIcon" />
                Polygon
                </li>
                <li className="list-group-item" onClick={() => switchNetwork(42161)}  style={{ cursor: 'pointer' }}>
            <img src="https://docs.waweswaps.com/images/chains/arbitrum.svg" className="networkIcon" />
                Arbitrum</li>
            <li className="list-group-item" onClick={() => switchNetwork(10)}  style={{ cursor: 'pointer' }}>
            <img src="https://docs.waweswaps.com/images/chains/optimism.svg" className="networkIcon" />
                Optimism</li>
            
          </ul>
        </div>
      )}
    </div>
    </>
  );
};

export default FloatingMenu;
