import React, { useState, useEffect, useContext } from "react";
import { useWeb3 } from "../Web3Context";
import Web3 from "web3";
import stakeContractABI from "../abis/StakeContractAbi.json";
import tokenSwapABI from "../abis/TokenSwapABI";
import WaweSwapsABI from "../abis/WaweSwapsABI";
import RecycleSwapABI from "../abis/WaweSwapsV1ABI.json";
import RecycleSwapStorageABI from "../abis/WaweSwapStorageV1ABI.json";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import BigNumber from "bignumber.js";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCaretDown,faCaretUp} from "@fortawesome/pro-solid-svg-icons";
import { faBell, faCog, faSpinnerThird, faCircleInfo } from "@fortawesome/pro-thin-svg-icons";
import SETTINGS from "../settings";
import Dropdown from 'react-bootstrap/Dropdown';
import { ERC20_ABI } from "../abis/erc20";

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import InfoModal from './InfoModal';
import ToastNotification from './ToastNotification';
import LoadingDots from "./LoadingDots";

const ClaimButton = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: var(--maincolor);
  font-weight: bold;
  font-size: 10px;
  border: 1px solid var(--maincolor);
  padding: 5px 20px;
  border-radius:100px;
  cursor: pointer;
  margin-left:0px;
 margin-top:12px;
  transition: all 0.2s;
  text-align:center;
  &:hover {
    background-color:var(--maincolor);
    color:#FFFFFF;
    transition: all 0.6s;
  }
`;

const SpinnerBox = styled.div`
display:inline-block !important;
background-color: transparent;
color: var(--maincolor);
font-weight: bold;
font-size: 20px;
margin-left:50px;
  text-align:center;
`;

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const ACTIVE = 'accordionActive';
  const CLOSED = 'accordionClosed';
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={isCurrentEventKey ? ACTIVE : CLOSED }
      onClick={decoratedOnClick}
    >
     <FontAwesomeIcon icon={isCurrentEventKey ? faCaretUp : faCaretDown } style={{fontSize:20}}  /> {children}
    </button>
  );
}
const UserStakes = ({ balanceFunction }) => {
  const { web3, selectedAccount, networkId } = useWeb3();
  const [stakes, setStakes] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [stakesLoaded, setStakesLoaded] = useState(false);
  const [swapAvailable, setSwapAvailable] = useState(false);
  const [gblBalance, setGblBalance] = useState('');
  const [gblAlowance, setGblAlowance] = useState('');
  const [recAlowance, setRecAlowance] = useState('0');
  const [swapTokenAmount, setSwapTokenAmount] = useState('');
  const [swapRewardAmount, setSwapRewardAmount] = useState('');
  const [recycleAmount, setRecycleAmount] = useState('');
  const [recBalance, setRecBalance] = useState(0);
  const [fee, setFee] = useState(0);
  const [numOfStakes, setNumOfStakes] = useState(1);
  const [claimInProgress, setClaimInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loadingText, setLoadingText] = useState("Loading");
    const [loadingStep, setLoadingStep] = useState(1);
    const [loadingNumber, setLoadingNumber] = useState(1);
  const [tokens, setTokens] = useState([]);
  const [selectedRecycleToken, setSelectedRecycleToken] = useState(null);
  const [selectedLabelRecycle, setSelectedLabelRecycle] = useState("Select");
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [unclaimedRewardsNet, setUnclaimedRewardsNet] = useState(0);
  const [unclaimedRewardsFee, setUnclaimedRewardsFee] = useState(0);

  const [showToast, setShowToast] = useState(false);
const [toastUrl, setToastUrl] = useState(null);
const [toastError, setToastError] = useState(false);
const [toastMessage, setToastMessage] = useState("-");



const displayToast = (msg, url = null,  error = false) => {
  setToastMessage(msg);
  setToastUrl(url);
  setToastError(error);
  setShowToast(true);
};


  const handleClaimSwap = async (index) => {
  
    setLoading(true);
    setLoadingNumber(1);
    setLoadingStep(1);
    setLoadingText("Claiming swap.");
      await handleWithdrawSwap(index);
      setLoading(false);
    
  
      getSwapsForUser(selectedAccount)
        .then(setSwaps)
        .catch((error) => console.error("Error fetching stakes:", error));
  };

  const handleCloseCollectModal = () => {
    setShowCollectModal(false);
  };
  const handleShowCollectModal = () => {
    setShowCollectModal(true);
  };

  const handleCloseSwap = async (index) => {
  
    setLoading(true);
    setLoadingNumber(1);
    setLoadingStep(1);
    setLoadingText("Closing swap.");
      await handleCloseSwapFunction(index);
      setLoading(false);
    
  
      getSwapsForUser(selectedAccount)
        .then(setSwaps)
        .catch((error) => console.error("Error fetching stakes:", error));
  };

  const handleWithdrawSwap = async (index) => {
    try {
      const web3 = new Web3(Web3.givenProvider);
      const contract = new web3.eth.Contract(
        RecycleSwapABI,
        SETTINGS.recycleSwapAddress
      );

  
      
      const withdrawTx = await contract.methods
        .withdraw(index)
        .send({
          from: selectedAccount,
          maxPriorityFeePerGas: null, maxFeePerGas: null,
        });
        displayToast('Swap claimed!', withdrawTx.transactionHash);
    } catch (error) {
      console.error("An error occurred:", error);
      displayToast(error.message, null, true);
    }
    
  };

  const handleCloseSwapFunction = async (index) => {
    try {
      const web3 = new Web3(Web3.givenProvider);
      const contract = new web3.eth.Contract(
        RecycleSwapABI,
        SETTINGS.recycleSwapAddress
      );

  
      
      const withdrawTx = await contract.methods
        .closeSwap(index)
        .send({
          from: selectedAccount,
          maxPriorityFeePerGas: null, maxFeePerGas: null,
        });
        displayToast('Swap closed!', withdrawTx.transactionHash);
    } catch (error) {
      console.error("An error occurred:", error);
      displayToast(error.message, null, true);
    }
    
  };

 
  const handleSelectRecycle = async (token) => {
    
    setSelectedLabelRecycle(`${token.symbol}`);
    await functionRecycleTokenChange(token.address);
  };

  const functionRecycleTokenChange = async (addr) => {
    

    

      const token = tokens.find((token) => token.address === addr);
      setSelectedRecycleToken(token);
      console.log(token);
      const rBal = await getBalanceERC(token.address);
      setRecBalance(rBal);
      const priceGbl = await getPriceNetwork(SETTINGS.globalTokenAddress);
      const priceToken = await getPriceNetwork(token.address);
        

      
      const recycleAM = ((parseFloat(swapTokenAmount) * parseFloat(web3.utils.fromWei(priceGbl, 'ether'))) / parseFloat(web3.utils.fromWei(priceToken, 'ether'))) / 100;
      setRecycleAmount(recycleAM.toFixed(4));
  
      const recTokenContract = new web3.eth.Contract(ERC20_ABI, token.address);
      const allowanceRec = await recTokenContract.methods.allowance(selectedAccount, SETTINGS.recycleSwapAddress).call();
      const decimals = await recTokenContract.methods.decimals().call();
      
      const formattedBalance = parseFloat(allowanceRec) / 10 ** decimals;
   
      setRecAlowance(formattedBalance.toFixed(4));

      if(parseFloat(recycleAM) > parseFloat(rBal)){
        setErrorText("Balance to low!");
        setSwapAvailable(false);
    }else{
        setErrorText("");
        setSwapAvailable(true);
    } 
    

    
  };

  const collectIncome = async () => {
    

    setLoading(true);
    try {
      const web3 = new Web3(Web3.givenProvider);
      const contract = new web3.eth.Contract(
        RecycleSwapABI,
        SETTINGS.recycleSwapAddress
      );

  
      
      const withdrawTx = await contract.methods
        .withdrawReward()
        .send({
          from: selectedAccount,
          maxPriorityFeePerGas: null, maxFeePerGas: null,
        });
        displayToast('Rewards collected.!', withdrawTx.transactionHash);
    } catch (error) {
      console.error("An error occurred:", error);
      displayToast(error.message, null, true);
    }
    handleCloseCollectModal();
    
    
    setLoading(false);
}



  const getBalanceERC = async (addr) => {
    if (!Web3.utils.isAddress(addr)) {
      //////alert("Please enter a valid contract address.");
      return;
    }

    const tokenContract = new web3.eth.Contract(ERC20_ABI, addr);

    try {
      const balance = await tokenContract.methods
        .balanceOf(selectedAccount)
        .call();
      const decimals = await tokenContract.methods.decimals().call();
      
      const formattedBalance = balance / 10 ** decimals;
      return formattedBalance.toFixed(4);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
    }
  };

  const handleSwap = async () => {
    setLoading(true);
    setLoadingNumber(3);
    setLoadingStep(1);
    setLoadingText("Approving GBL transaction.");
    // Approve the contract to spend tokens on behalf of the user
    const tokenContract = new web3.eth.Contract(ERC20_ABI, SETTINGS.globalTokenAddress);
    const amountInWei = web3.utils.toWei(swapTokenAmount.toString(), 'ether');
    const allowanceToken = await tokenContract.methods.allowance(selectedAccount, SETTINGS.recycleSwapAddress).call();
    if(parseFloat(allowanceToken) < parseFloat(amountInWei)){
      await tokenContract.methods.approve(SETTINGS.recycleSwapAddress, amountInWei).send({ 
          from: selectedAccount,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null  });

          setLoadingStep(2);
          setLoadingText("Approving token transaction.");
    }

        const recTokenContract = new web3.eth.Contract(ERC20_ABI, selectedRecycleToken.address);
        const decimals = await recTokenContract.methods.decimals().call();
        const recAmountInWei = (parseInt(recycleAmount)) * 10 ** decimals;
        const allowanceRecToken = await recTokenContract.methods.allowance(selectedAccount, SETTINGS.recycleSwapAddress).call();
         if(parseFloat(allowanceRecToken) < parseFloat(recAmountInWei)){
          await recTokenContract.methods.approve(SETTINGS.recycleSwapAddress, recAmountInWei.toFixed(0).toString()).send({ 
              from: selectedAccount,
              maxPriorityFeePerGas: null,
              maxFeePerGas: null  });
          }
            setLoadingStep(3);
            setLoadingText("Opening WaweSwap V1");
            const contract = new web3.eth.Contract(
              RecycleSwapABI, 
              SETTINGS.recycleSwapAddress);
              const fee =  await contract.methods.FEE().call();
          const tx= await contract.methods.buySwapRecycle(
              amountInWei,
              selectedRecycleToken.address,
              recAmountInWei.toFixed(0).toString()
          ).send({ 
              from: selectedAccount,
              value: parseFloat(fee) + 100000000000000,
              maxPriorityFeePerGas: null,
              maxFeePerGas: null  });
              displayToast('Swap opened.', tx.transactionHash);

              getSwapsForUser(selectedAccount)
              .then(setSwaps)
              .catch((error) => displayToast(error.message, null, true));
  setSwapTokenAmount('');
  setSwapRewardAmount('');
  setRecycleAmount('');
  setSwapAvailable(false);
  setLoading(false);
            };


  
 


  const calculateRewards1 = async () => {
    const contract = new web3.eth.Contract(RecycleSwapABI, SETTINGS.recycleSwapAddress);
        const contractStorage = new web3.eth.Contract(RecycleSwapStorageABI, SETTINGS.recycleSwapStorageAddress);
        const GBL_REWARDS_TO_DISTRIBUTE = await contract.methods.GBL_REWARDS_TO_DISTRIBUTE().call();
        const TOTAL_SWAP_AMOUNT = await contract.methods.TOTAL_SWAP_AMOUNT().call();
        const allUsers = await contractStorage.methods.getAllUsers().call();
        const distributeAmount = 50000;
        //const distributeAmount = parseFloat(web3.utils.fromWei(GBL_REWARDS_TO_DISTRIBUTE, 'ether')).toFixed(6);
        const totalSwapAmount = parseFloat(web3.utils.fromWei(TOTAL_SWAP_AMOUNT, 'ether')).toFixed(6);
        console.log(distributeAmount);
        console.log(totalSwapAmount);

        const usersArr = [];
        const indexesArr = [];
        const rewardsArr = [];
        for(let i=0;i<allUsers.length;i++){
          const u = allUsers[i];
     
          const userSwaps = await contractStorage.methods.getSwaps(u).call();
          for(let j=0;j<userSwaps.length;j++){
            const swapAmount = parseFloat(web3.utils.fromWei(userSwaps[j].amount, 'ether')).toFixed(6);
            const swapReward = parseFloat(web3.utils.fromWei(userSwaps[j].rewards, 'ether')).toFixed(6);

            if(parseFloat(swapAmount) <= 0){
              continue;
            }
            const rewardAmount = (parseFloat(distributeAmount) * parseFloat(swapAmount)) / parseFloat(totalSwapAmount); 
            let newReward = parseFloat(swapReward) + rewardAmount;
            const maxReward = parseFloat(swapAmount) + (parseFloat(swapAmount) * 36 / 100);
            if(newReward >= maxReward){
              newReward = maxReward;
              console.log("zapret");
              console.log(u);
              console.log(j);
            }
            usersArr.push(u);
            indexesArr.push(j);
            rewardsArr.push(web3.utils.toWei(newReward.toFixed(6), 'ether'));


          }
        }
        console.log("usersArr");
        console.log(JSON.stringify(usersArr));
        console.log("indexesArr");
        console.log(JSON.stringify(indexesArr));
        console.log("rewardsArr");
        console.log(JSON.stringify(rewardsArr));
  };


  const exportSwaps = async () => {
    const contractStorage = new web3.eth.Contract(RecycleSwapStorageABI, SETTINGS.recycleSwapStorageAddress);
        
    let csvResult = "";
    let swapsArr = [];
  
    // Start from the head of the list
    let currentUid = await contractStorage.methods.headIndex().call();
    let lastIndex = await contractStorage.methods.lastIndex().call();
 
    while (currentUid !== lastIndex) {
      try {
        const swap = await contractStorage.methods.swaps(currentUid).call();
        console.log(currentUid + " / " + lastIndex);
  
        const swapAmount = parseFloat(web3.utils.fromWei(swap.amount, 'ether')).toFixed(0);
        const rewardAmount = parseFloat(web3.utils.fromWei(swap.rewards, 'ether')).toFixed(0);
     
        swapsArr.push([swapAmount, rewardAmount, swap.user]);
        csvResult += currentUid.toString() + ";" 
        + swap.user + ";" 
        + swapAmount + ";" 
        + rewardAmount +  "\n";
        
        // Move to the next swap in the list
        currentUid = swap.next;
      } catch (error) {
        console.log("Iteration error or reached the end:", error);
        break; // Exit the loop in case of error (e.g., end of list)
      }
    }
  /*
    console.log("swapsArr");
    console.log(JSON.stringify(swapsArr));
  */
        // generate csv file
        console.log(JSON.stringify(swapsArr));
        const currentDateTime = getCurrentDateTimeString();
        const fileName = `swapV1_export_${currentDateTime}.csv`;
        const blob = new Blob([csvResult], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
  
  };


  const exportSwaps1 = async () => {
        const contractStorage = new web3.eth.Contract(RecycleSwapStorageABI, SETTINGS.recycleSwapStorageAddress);
        
        
        let csvResult = "";
        


        const swapsArr = [];
  
        try{
        for(let i=0;i<100000000;i++){
          const swap = await contractStorage.methods.swaps(i).call();
          console.log(swap);
          const swapAmount = parseFloat(web3.utils.fromWei(swap.amount, 'ether')).toFixed(6);
            const swapReward = parseFloat(web3.utils.fromWei(swap.rewards, 'ether')).toFixed(6);
            swapsArr.push([swapAmount, swapReward, swap.user,  getTimeLeft(swap.createdAt)]);
            csvResult += swapAmount + ";" + swapReward + ";" + swap.user + ";" + getTimeLeft(parseInt(swap.createdAt)) +"\n";


        }
        }catch(error){
          console.log("DONE");
        }
        console.log("swapsArr");
        console.log(JSON.stringify(swapsArr));
        const currentDateTime = getCurrentDateTimeString();
    const fileName = `swapV1_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    // Trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
        
  };

  const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
  };

  const getTimeLeft =  (createdAt) => {
    // Convert createdAt to milliseconds for the start time and add 30 days for the end time
    const endTime = new Date((createdAt + 90 * 24 * 60 * 60) * 1000);
   
    // Current time
    const now = new Date();
   
    // Calculate the difference in milliseconds
    const timeLeft = endTime - now;
   
    // Convert time left into days and hours
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
   
    // Return a string with days and hours left
    return `${daysLeft} days and ${hoursLeft} hours left`;
   
           };
  const getSwapsForUser = async (userAddress) => {
    try {
        const contract = new web3.eth.Contract(RecycleSwapABI, SETTINGS.recycleSwapAddress);
        const contractStorage = new web3.eth.Contract(RecycleSwapStorageABI, SETTINGS.recycleSwapStorageAddress);

   
      /*
                const contract1 = new web3.eth.Contract(RecycleSwapABI, SETTINGS.recycleSwapAddress);
                const uArr = [];
                const sAmArr = [];
                const sRArr = [];
                let csvResult = "";
                try{
                  for(let i=0;i<2000;i++){
                    console.log(i);
                    const u = await contract1.methods.users(i).call();
                    //const pos = await contract1.methods.positions(u).call();
                    //sAmArr.push(pos.stakeAmount);
                    //uArr.push(u);
                    //continue;
                    
                   
                    const swapLength1 = await contract1.methods.getSwapLength(u).call();
                    
                    for (let j = 0; j < swapLength1; j++) {
                      const swapDetails1 = await contract1.methods.getSwapDetails(u, j).call();
                      const amount = swapDetails1[0];
                      const rew = swapDetails1[1];
                      //const sellAm = swapDetails1[2];
            
                      uArr.push(u);
                      sAmArr.push(amount);
                      sRArr.push(rew);
                      let csvRow =
                            u +
                            ";" +
                            amount +
                            ";" +
                            rew +
                            ";" +
                            "\n";
                          csvResult += csvRow;
                    };
                  }
                }catch(error){

                }
                console.log("uArr");
                console.log(JSON.stringify(uArr));
                console.log("sAmArr");
                console.log(JSON.stringify(sAmArr));
                console.log("sRArr");
                console.log(JSON.stringify(sRArr));
                const currentDateTime = "asdfasdf";
                const fileName = `swapV1_export_${currentDateTime}.csv`;
                const blob = new Blob([csvResult], { type: "text/plain" });
            
                // Create a URL for the Blob
                const url = window.URL.createObjectURL(blob);
            
                // Create a temporary <a> element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
            
                // Trigger the download
                a.click();
            
                // Clean up by revoking the object URL
                window.URL.revokeObjectURL(url);
                return;
        */
      
        const userSwaps = await contractStorage.methods.getSwaps(userAddress).call();
        const userRewardAmountWei = await contractStorage.methods.gblRewards(userAddress).call();
        let userRewardAmount = parseFloat(web3.utils.fromWei(userRewardAmountWei, 'ether'));
   



        const swaps = [];
        for (let i = 0; i < userSwaps.length; i++) {
        
          const am = parseFloat(web3.utils.fromWei(userSwaps[i][0], 'ether'));
          const rew = parseFloat(web3.utils.fromWei(userSwaps[i][1], 'ether'));
          const maxRew = am + (am * 36)/100;
          swaps.push({
            uid: i,
            amount: am.toFixed(4),
            rewards: rew.toFixed(4),
            maxReward: maxRew.toFixed(4),
            timeLeft: getTimeLeft(parseInt(userSwaps[i][3]))
            
          });
      
        }

        let feePercentage = 0.005;
        if(userRewardAmount < 100) {
            feePercentage = 0.02; // 2% for less than 100 ether
        } else if(userRewardAmount >= 100 && userRewardAmount < 1000) {
            feePercentage = 0.01; // 1% for 100 to 1000 ether
        } 
        let feeAmount = userRewardAmount * feePercentage;
        let netRewardAmount = userRewardAmount - feeAmount;
        setUnclaimedRewards(userRewardAmount.toFixed(4));
        setUnclaimedRewardsFee(feeAmount.toFixed(4));
        setUnclaimedRewardsNet(netRewardAmount.toFixed(4));

        return swaps.reverse();
    } catch (error) {
      console.error('Error fetching swap data:', error);
    }
  };

  const getPriceNetwork = async (tokenAddress) => {

    const routerABI = [
      {
        "constant": true,
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amountIn",
            "type": "uint256"
          },
          {
            "internalType": "address[]",
            "name": "path",
            "type": "address[]"
          }
        ],
        "name": "getAmountsOut",
        "outputs": [
          {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      }
    ];
    
   
    const usdtAddress = SETTINGS.usdtAddress[networkId];

    const routerContract = new web3.eth.Contract(routerABI, "0x10ED43C718714eb63d5aA57B78B54704E256024E");

    const tokContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
    const decimals = await tokContract.methods.decimals().call();
          
    const amountIn ="1"+"0".repeat(decimals); 
  
    
    try {
        const amountsOut = await routerContract.methods.getAmountsOut(amountIn, [tokenAddress, usdtAddress]).call();
        
        
        let priceInUSDT = amountsOut[1];
        
        return priceInUSDT;
    } catch (error) {
        console.error('Error fetching price:', error);
        return 999;
    }
};

  const handleBuyAmountChange = async (e) => {
    const amount = e.target.value;
    setSwapTokenAmount(amount);
    setSwapRewardAmount((parseFloat(amount) + (parseFloat(amount) * 36)/100).toFixed(4));
    setRecycleAmount(0);
    if (amount === "") {
      setSwapAvailable(false);
    } else {
      if(parseFloat(amount) > parseFloat(gblBalance)){
          setErrorText("Balance to low!");
          setSwapAvailable(false);
      }else{
        setErrorText("");
        if(selectedRecycleToken){
          const priceGbl = await getPriceNetwork(SETTINGS.globalTokenAddress);
      const priceToken = await getPriceNetwork(selectedRecycleToken.address);
     

        
      const recycleAM = ((parseFloat(amount) * parseFloat(web3.utils.fromWei(priceGbl, 'ether'))) / parseFloat(web3.utils.fromWei(priceToken, 'ether'))) / 100;

      setRecycleAmount(recycleAM.toFixed(4));
        
                  if(parseFloat(recycleAM) > parseFloat(recBalance)){
                    setErrorText("Balance to low!");
                    setSwapAvailable(false);
                }else{
                    setErrorText("");
                    setSwapAvailable(true);
                } 
                
      }
      if(parseFloat(amount) > parseFloat(gblBalance)){
        setErrorText("GBL balance to low.");
        setSwapAvailable(false);
    }
    }
  
  }
};

  useEffect(() => {
  

    const getFee = async () => {
      const web3 = new Web3(Web3.givenProvider);
      const tokenSwapContract = new web3.eth.Contract(
        tokenSwapABI,
        SETTINGS.tokenSwapAddress
      );
      const result = await tokenSwapContract.methods.treasuryPaymentWithdrawFee().call();
      setFee(web3.utils.fromWei(result, "ether"));

      setGblBalance(await getBalanceERC(SETTINGS.globalTokenAddress));
      const tokenContract = new web3.eth.Contract(ERC20_ABI, SETTINGS.globalTokenAddress);
      const allowanceToken = await tokenContract.methods.allowance(selectedAccount, SETTINGS.recycleSwapAddress).call();
    const amountInWei = web3.utils.fromWei(allowanceToken, 'ether');
    
      setGblAlowance(amountInWei);
    };

    if (selectedAccount && !stakesLoaded) {
      setStakesLoaded(true);
      setTokens(SETTINGS.tokensDataV1.sort((a, b) => (a.price > b.price ? -1 : 1)));
      getFee();
      getSwapsForUser(selectedAccount)
        .then(setSwaps)
        .catch((error) => console.error("Error fetching swaps:", error));
        /*
      loadSwaps(selectedAccount)
        .then(setStakes)
        .catch((error) => console.error("Error fetching stakes:", error));
        */
    }
   
  }, [selectedAccount, numOfStakes, stakes, swaps]);

  return (
   
    

    
    
      <div className="waweSwap">
          <ToastNotification show={showToast} onClose={() => setShowToast(false)} message={toastMessage} url={toastUrl} error={toastError} />
        <InfoModal link="https://docs.waveswaps.com/products-and-features/swap-v1" message="Swap V1 stands as the cornerstone of our ecosystem, revolutionizing token liquidity with its user-friendly and intuitive model. Unlike conventional staking mechanisms, Swap V1 rewards participants through the accumulation and distribution of GBL tokens, derived solely from swapping activities. Users initiate Swap V1 by providing swapping liquidity in GBL tokens, balanced with recycle tokens, calculated based on DEX exchange rates. This innovative model ensures a reward of 36% more GBL tokens than the liquidity provided, irrespective of market prices." />
        <div className="row">
           
           {loading ? (
            <div className='col-12'>
          <center><FontAwesomeIcon icon={faSpinnerThird} spin color="var(--maincolor)"  className='bigLoader' />
          <p className="smallTitle">
          Step <br /><b>{loadingStep} </b> of <b> {loadingNumber}</b>    <br /> {loadingText} <LoadingDots />
                            <br />
                          </p>
          </center>
          </div>
      
        ) : (  
          <div className='col-12'>
                   {selectedAccount &&(<>
          {selectedAccount.toLowerCase() ===
              SETTINGS.ownerAddress.toLowerCase() && (
                <button onClick={exportSwaps}>Export</button>
                
              )}</>
              )}
       <div className="card">
        <div className="card-body text-center">
        <h4>Multiply GBL Tokens</h4>
     
        <div className='formBox'>
              </div>
        <div className='formBox'>
              <label className="form-label">GBL liquidity</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                value={swapTokenAmount}
                onChange={handleBuyAmountChange}
              />
              <label className="form-label"><small>Balance: </small> {gblBalance}</label>
              <br />
              <label className="form-label"><small>Allowance: </small> {gblAlowance}</label>
              
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect"className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px' }} />GBL
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdownMenuStyle'>

    
          <Dropdown.Item key="GBL">
            <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px' }} />GBL
          </Dropdown.Item>
    

      </Dropdown.Menu>
    </Dropdown>
  </div>
</div>

              </div>
<br />
              <div className='formBox'>
              <label className="form-label">Recycle token</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                value={recycleAmount}
                disabled
              />
              <label className="form-label"><small>Balance: </small> {recBalance}</label>
              <br />
              <label className="form-label"><small>Allowance: </small> {recAlowance}</label>
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons[selectedLabelRecycle]} alt="" style={{ width: '20px', marginRight: '10px' }} />{selectedLabelRecycle}
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdownMenuStyle'>

        {tokens.map((token) => (
          <Dropdown.Item key={token.address} onClick={() => handleSelectRecycle(token)}>
            <img src={SETTINGS.tokenIcons[token.symbol]} alt="" style={{ width: '20px', marginRight: '10px' }} />{token.symbol}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
</div>
<hr />
<div className='row'>
<div className='col-1'><img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px' }} /></div>
      <div className='col-5'><label className="form-label">Exit Value <small className="smallText">(+36%)</small>  </label></div>
      <div className='col-6'><label className="form-label"> <small className="smallTitle">{swapRewardAmount} </small><small>GBL</small></label></div>

              </div>
              <hr />
              </div>
              <div className="d-grid gap-2">
            <small className="smallText text-left" style={{paddingBottom:"20px"}}>
              By clicking the button "Swap", you agree with WaweSwaps <a href="https://docs.waweswaps.com/Terms_And_Conditions_WS.pdf" target="_blank">Terms and Conditions.</a>

                </small>
              
              </div>
              <div className="d-grid gap-2">
            {swapAvailable ? ( <div>
                {loading ? (
                          <SpinnerBox><Spinner animation="border" variant="primary" /></SpinnerBox>
                        ) : (<button className="large-button-blue" type="button" onClick={handleSwap}>Swap</button>)}
                        </div>):(
                <div>
                <button className="large-button-blue-disabled" type="button" >Swap</button>
                <center><div className="error-text error-text" >{errorText}</div></center>
                </div>
            )}
            </div>
            <div className="d-grid gap-2">
            <small className="smallText text-left" style={{paddingTop:"20px"}}>
            Swapping income is distributed when enough total BNB GAS has accumulated to execute the distribution function within the V1 smart contract.
                </small>
</div>
        </div>
        </div>
        <br />
       <div className="row" style={{textAlign:"center"}}>


<div className="col-3 col-lg-4"><small className='smallTitle'>Income:</small></div>
<div className="col-6 col-lg-4"><img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px', marginTop:"-3px" }} /><small> {unclaimedRewards}</small> <small className='smallText'>GBL</small></div>
<div className="col-3 col-lg-4"><button type="button" className="mediumButton" style={{marginTop:"0px"}}  onClick={handleShowCollectModal}>Claim</button></div>
<div className="col-12">


</div>

</div>

        <Accordion defaultActiveKey="0">

{swaps.map((swap, index) => (
  <Card key={index}>
  <Card.Header>
  <div className="row ">
  <div className="col-5">
    <small className='smallTitleSmall' style={{ lineHeight: '30px', marginTop: "5px" }}> <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px', marginTop:"-3px" }} /> {swap.rewards} <small className='smallText'> GBL</small></small>
    </div>

  <div className="col-1">

   
    </div>
    <div className="col-5">
    <small className='smallTitleSmall' style={{ lineHeight: '30px', marginTop: "5px" }}> <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px', marginTop:"-3px" }} /> {swap.amount == 0 ? (<>{swap.rewards}</>):(<>{swap.maxReward}</>)}<small className='smallText'> GBL</small> </small>
    </div>
  <div className="col-1"> <ContextAwareToggle eventKey={String(index)}></ContextAwareToggle></div>
    
    <div className="col-12">
    {swap.amount == 0 ? (<ProgressBar now={100} min={0} max={100}  striped variant="info"  className='swapProgress' ></ProgressBar>):(
<ProgressBar now={swap.rewards} min={0} max={swap.maxReward}  striped variant="info" animated className='swapProgress' ></ProgressBar>
)}
</div>
</div>
  </Card.Header>
  <Accordion.Collapse eventKey={String(index)}>
    <Card.Body>
   
    <div className="row">
                  
                  <div className="col-12 col-lg-6">
                    <label className="form-label">Liquidity </label><br />
               <small> <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.amount} GBL</small>
               <br />
               <br />
                    </div>
                    <div className="col-12 col-lg-6">
                    <label className="form-label">Recycling income </label><br />
               <small> <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.rewards} GBL</small>
               <br />
               <br />
                    </div>
                    <div className="col-12 col-lg-6">
                    <label className="form-label">Exit value <small className="smallText">(+36%)</small> </label><br />
               <small> <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.maxReward} GBL</small>
               <br />
                    </div>
                    <div className="col-12 col-lg-6">
                    <label className="form-label">Earned VGBL </label><br />
               <small> <img src={SETTINGS.tokenIcons["VGBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.amount} VGBL</small>
               <br />
                    </div>
             
      
                 
                    
                  
                  </div>
                
    </Card.Body>
  </Accordion.Collapse>
</Card>
))}


      
    
    </Accordion>
        </div>
        )}
        </div>
        
    
        <div className="row">
            
          
            <div className="col-12 col-lg-6 text-center">
            <Modal show={showCollectModal} onHide={handleCloseCollectModal}>
                    <Modal.Header closeButton>
                      <Modal.Title><center>Claim rewards</center></Modal.Title>
                      
                    </Modal.Header>
                    <Modal.Body>
            <div className="card ">
                  <div className="card-body text-center">
                   
                    <strong>Available GBL Rewards:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewards}
                    disabled
                />
                    <strong>Amount to Claim:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewardsNet}
                    disabled
                />
                    <strong>Claim Income Processing Fee:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewardsFee}
                    disabled
                />
                
                <br />

                <br />
                {unclaimedRewards > 0 ? (<>
                {loading ? (
                          <SpinnerBox><Spinner animation="border" variant="primary" /></SpinnerBox>
                        ) : (<button className="large-button-blue" type="button" onClick={collectIncome}>Claim</button>)}
                        </> ) : (null)}
                        
           
            </div>
            </div>
            <br />
            <small className='smallText'>
                  <b> Reward Claiming Fee Requests:</b>
<ul >
<li>Up to 99 GBL: 2%</li>
<li>From 100 to 999 GBL: 1%</li>
<li>1000 GBL onward: 0.5%</li>
</ul>
                  </small>
            </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                  </Modal>

            </div>

       

     


        
</div>
</div>
  );
};

export default UserStakes;
