import React, { useState, useEffect } from "react";
import { useWeb3 } from "../Web3Context";

import SETTINGS from "../settings";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/pro-light-svg-icons";


const Helper = ({message, link}) => {
    const { web3, selectedAccount } = useWeb3();
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
      };
    const handleCloseModal = () => {
        setShowModal(false);
      };
  useEffect(() => {
    if (selectedAccount) {
     
    }
  }, [selectedAccount]);

  return (
    <div>
      <button className="infoButton" onClick={handleShowModal} >
                        <FontAwesomeIcon icon={faCircleInfo} style={{marginRight:"0px"}} />
                          </button>
                          <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Info</Modal.Title>
                      
                    </Modal.Header>
                    <Modal.Body>
                    <p style={{fontSize:15, padding:10, lineHeight:"26px", textAlign:"justify", backgroundColor:"#FFFFFF", padding:10, borderRadius:20}}>{message}
                    <br />
                    <br />
                    <a href={link} target="_blank">Learn more</a>
                    </p>
                    
            </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                  </Modal>

    </div>
  );
};

export default Helper;
