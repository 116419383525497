import logo from './logo.svg';
import './App.css';
import MetaMaskLogin from './MetaMaskLogin';
import styled from '@emotion/styled';


function App() {
  return (
    <>



      <div className="App">  
      <MetaMaskLogin />
      </div>
    </>
  );
}

export default App;
