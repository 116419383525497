import React, { useState, useEffect, useContext } from 'react';
import { useWeb3 } from "../Web3Context";
import Web3 from 'web3';

import SETTINGS from "../settings";
import WaweSwapsABI from "../abis/WaweSwapsV2ABI";
import WaweSwapStorageABI from "../abis/WaweSwapStorageV2ABI";



import { ERC20_ABI } from "../abis/erc20";
import styled from "@emotion/styled";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { parse } from '@fortawesome/fontawesome-svg-core';
import Dropdown from 'react-bootstrap/Dropdown';
import ToastNotification from './ToastNotification';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingDots from "./LoadingDots";
import InfoModal from "./InfoModal";
import AdminPanelV2 from "./AdminPanelV2";

import {faCaretDown,faCaretUp} from "@fortawesome/pro-solid-svg-icons";
import {faSpinnerThird, faArrowsDownToLine, faCopy} from "@fortawesome/pro-thin-svg-icons";

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import TooltipInfoReverse from './TooltipInfoReverse';

const ClaimButton = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: #999999;
  font-weight: bold;
  font-size: 10px;
  border: 1px solid #999999;
  padding: 5px 20px;
  border-radius:100px;
  cursor: pointer;
  margin-left:0px;
 margin-top:12px;
  transition: all 0.2s;
  text-align:center;
  &:hover {
    
    border: 1px solid var(--maincolor);
    color: var(--maincolor);
    transition: all 0.6s;
  }
`;


const SpinnerBox = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: var(--maincolor);
  font-weight: bold;
  font-size: 15px;
  border: 1px solid var(--maincolor);
  padding: 10px;
  border-radius:100px;
  cursor: pointer;
  width:100%;
  transition: all 0.2s;
  text-align:center;
`;

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const ACTIVE = 'accordionActive';
  const CLOSED = 'accordionClosed';
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={isCurrentEventKey ? ACTIVE : CLOSED }
      onClick={decoratedOnClick}
    >
     <FontAwesomeIcon icon={isCurrentEventKey ? faCaretUp : faCaretDown } style={{fontSize:20}}  /> {children}
    </button>
  );
}

function WaweSwapRecycle() {
    const { web3, selectedAccount, networkId } = useWeb3();
    const [stakeAmount, setStakeAmount] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [swapAvailable, setSwapAvailable] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [recBalance, setRecBalance] = useState(0);
    const [ercBalance, setErcBalance] = useState(0);
    const [gblBalance, setGblBalance] = useState(0);
    const [gblAmountValue, setGblAmountValue] = useState(0);
    const [unclaimedRewards, setUnclaimedRewards] = useState(0);
    const [unclaimedRewardsNet, setUnclaimedRewardsNet] = useState(0);
    const [unclaimedRewardsFee, setUnclaimedRewardsFee] = useState(0);
    const [swapTokenAmount, setSwapTokenAmount] = useState('');
    const [gblBuyAmount, setGblBuyAmount] = useState('');
    const [gblSellAmount, setGblSellAmount] = useState('');
    const [recycleAmount, setRecycleAmount] = useState('');
    const [refferalAddress, setRefferalAddress] = useState('');
    const [buyTokens, setBuyTokens] = useState([]);
    const [userSwaps, setUserSwaps] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [selectedToken, setSelectedToken] = useState(null);
    const [selectedRecycleToken, setSelectedRecycleToken] = useState(null);
    const [selectDisabled, setSelectDisabled] = useState(false);
    const [showStakeModal, setShowStakeModal] = useState(false);
    const [showCollectModal, setShowCollectModal] = useState(false);
    const [stakeSize, setStakeSize] = useState(0);
    const [showStakeCloseModal, setShowStakeCloseModal] = useState(false);
    const [loadingText, setLoadingText] = useState("Loading");
    const [loadingStep, setLoadingStep] = useState(1);
    const [loadingNumber, setLoadingNumber] = useState(1);
    const [selectedLabel, setSelectedLabel] = useState("Select");
    const [selectedLabelRecycle, setSelectedLabelRecycle] = useState("Select");
    const [showToast, setShowToast] = useState(false);
    const [toastUrl, setToastUrl] = useState(null);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("-");
    
    
    
    const displayToast = (msg, url = null,  error = false) => {
      setToastMessage(msg);
      setToastUrl(url);
      setToastError(error);
      setShowToast(true);
    };

    const handleSelect = (token) => {
      setSelectedLabel(`${token.symbol}`);
      handleTokenChange({ target: { value: token.address } });
    };
    const handleSelectRecycle = (token) => {
      setSelectedLabelRecycle(`${token.symbol}`);
      handleRecycleTokenChange({ target: { value: token.address } });
    };


   
      const handleCloseCollectModal = () => {
        setShowCollectModal(false);
      };
      const handleShowCollectModal = () => {
        setShowCollectModal(true);
      };


    
      const handleCloseSwapModal = () => {
        setSwapTokenAmount('');
        setGblBuyAmount(0);
        setGblSellAmount(0);
        setRecycleAmount('');
        setRefferalAddress('');
        setSwapAvailable(false);
        setSelectedRecycleToken(null);
      };

      const getBalance = async () => {
        if (!Web3.utils.isAddress(selectedAccount)) {
          //alert("Please enter a valid Ethereum address.");
          return;
        }
    
        try {
          const balanceWei = await web3.eth.getBalance(selectedAccount);
          const balanceEth = web3.utils.fromWei(balanceWei, "ether");
          return parseFloat(balanceEth).toFixed(6);
        } catch (error) {
          console.error("Error fetching ETH balance:", error);
        }
      };


      const getBalanceERC = async (addr) => {
        if (!Web3.utils.isAddress(addr)) {
          ////alert("Please enter a valid contract address.");
          return;
        }
    
        const tokenContract = new web3.eth.Contract(ERC20_ABI, addr);
    
        try {
          const balance = await tokenContract.methods
            .balanceOf(selectedAccount)
            .call();
          const decimals = await tokenContract.methods.decimals().call();
          
          const formattedBalance = balance / 10 ** decimals;
          return formattedBalance.toFixed(6);
        } catch (error) {
          console.error("Error fetching token balance:", error);
          return 0;
        }
      };
      
    

    useEffect(() => {
      var sp = new URLSearchParams(window.location.search)
          const ref = sp.get("reff");
          setRefferalAddress(ref);
        if (selectedAccount) {
          
            firstLoad();
            handleSelect({"symbol": "BNB", "address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"});
    }else{
      setGblBalance(0);
      setUserSwaps([]);
      handleSelect({"symbol": "BNB", "address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"});
    }
    }, [selectedAccount, buyTokens]);

      const firstLoad = async () => {
        await loadUserData();
        if(buyTokens.length == 0){
            showAvailableTokens(selectedAccount);
        }
        
      }

      const loadUserData = async () => {
           
      
                setGblBalance(await getBalanceERC(SETTINGS.globalTokenAddress));
                try{
              
                const swaps = await getSwapsForUser(selectedAccount);
                const contract = new web3.eth.Contract(WaweSwapsABI, SETTINGS.waweSwapAddress);
                const myRefferer = await contract.methods.referrals(selectedAccount).call();
                if(myRefferer!="0x0000000000000000000000000000000000000000"){
                  setRefferalAddress(myRefferer);
                }
          
                
                setUserSwaps(swaps);
                }catch(error){}
                setIsLoading(false);
            
        };

        const getSwapsForUser = async (userAddress) => {
            try {
                const contract = new web3.eth.Contract(WaweSwapsABI, SETTINGS.waweSwapAddress);
                const contractStorage = new web3.eth.Contract(WaweSwapStorageABI, SETTINGS.waweSwapStorageAddress);
                
                
       
              const userSwaps = await contractStorage.methods.getSwaps(userAddress).call();
              const userRewardAmountWei = await contractStorage.methods.usdtRewards(userAddress).call();
                let swaps = [];
                let userRewardAmount = parseFloat(web3.utils.fromWei(userRewardAmountWei, 'ether'));
              for (let i = 0; i < userSwaps.length; i++) {
            
                

                
          
                const temp = {
                  uid: i,
                  amount: parseFloat(web3.utils.fromWei(userSwaps[i][0], 'ether')).toFixed(2),
                  sellAmount: parseFloat(web3.utils.fromWei(userSwaps[i][1], 'ether')).toFixed(4),
                  maxSellAMount: parseFloat(web3.utils.fromWei(userSwaps[i][2], 'ether')).toFixed(4),
                  soldAmount: (parseFloat(web3.utils.fromWei(userSwaps[i][2], 'ether')) - parseFloat(web3.utils.fromWei(userSwaps[i][1], 'ether'))).toFixed(4),
                  timeLeft: getTimeLeft(parseInt(userSwaps[i][4]))
                };
             
                swaps.push(temp);
                
                

              }

              
           
              setUnclaimedRewards(userRewardAmount.toFixed(4));

              let feePercentage;
              if(userRewardAmount < 100) {
                  feePercentage = 0.02; // 2% for less than 100 ether
              } else if(userRewardAmount >= 100 && userRewardAmount < 1000) {
                  feePercentage = 0.01; // 1% for 100 to 1000 ether
              } else {
                  feePercentage = 0.005; // 0.5% for 1000 ether or more
              }

              let feeAmount = userRewardAmount * feePercentage;
              let netRewardAmount = userRewardAmount - feeAmount;
              setUnclaimedRewardsFee(feeAmount.toFixed(4));
              setUnclaimedRewardsNet(netRewardAmount.toFixed(4));

              return swaps.reverse();
            } catch (error) {
              console.error('Error fetching swap data:', error);
            }
          };



        const getTimeLeft =  (createdAt) => {
          // Convert createdAt to milliseconds for the start time and add 30 days for the end time
          const endTime = new Date((createdAt + 30 * 24 * 60 * 60) * 1000);
         
          // Current time
          const now = new Date();
         
          // Calculate the difference in milliseconds
          const timeLeft = endTime - now;
         
          // Convert time left into days and hours
          const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hoursLeft = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
         
          // Return a string with days and hours left
          return `${daysLeft} days and ${hoursLeft} hours left`;
         
                 };


    const handleTokenChange = async (e) => {
      try{
        const selectedAddress = e.target.value;
        if (selectedAddress === "") {
          setSelectedToken(null);
          setSelectDisabled(false);
        } else {
          const token = buyTokens.find((token) => token.address === selectedAddress);
          setSelectedToken(token);
          if(token == undefined) return;
          if(token.symbol === "BNB"){
            setErcBalance(await getBalance());
          }else{
            setErcBalance(await getBalanceERC(token.address));
          }
          setSwapTokenAmount('');
          setGblBuyAmount(0);
          setGblSellAmount(0);
          setSelectDisabled(false);
          
        }
        setSwapAvailable(false);
      }catch(error){
        console.log(error);
      }
      };
    const handleRecycleTokenChange = async (e) => {

        await functionRecycleTokenChange(e.target.value);
    };
    const functionRecycleTokenChange = async (selectedAddress) => {
        if (selectedAddress === "") {
         
       
        } else {

          

          const token = tokens.find((token) => token.address === selectedAddress);
          if(token == undefined) return;
          setSelectedRecycleToken(token);
          
            setSwapAvailable(true);
      
     
        }
      };
    const handleRefferalAddressChange = async (e) => {
      const addr = e.target.value;
      if(addr.length == 0) return;
      if (!Web3.utils.isAddress(addr)) {
        displayToast("Wrong address type", null, true);
        setRefferalAddress("");
        return;
      }
      if (addr.toLowerCase() == selectedAccount.toLowerCase()) {
        displayToast("You can't use your own address.", null, true);
        setRefferalAddress("");
        return;
      }
     
        setRefferalAddress(addr);
    }

    const handleBuyAmountChange = async (e) => {
        const amount = e.target.value;
        setSwapTokenAmount(amount);
        
          setGblBuyAmount(0);
          setGblSellAmount(0);
          setRecycleAmount(0);
          
        if (amount === "") {
          setSwapAvailable(false);
        } else {
            if(parseFloat(amount) > parseFloat(ercBalance)){
                setErrorText("Balance to low!");
                setGblBuyAmount(0);
                setGblSellAmount(0);
                setSwapAvailable(false);
            }else{
                setErrorText("");

                
                let tokenPriceUSDT = 1 * Math.pow(10, 18);
                if(selectedToken.symbol === "USDT"){}else{
                  tokenPriceUSDT = await getUniswapV3PriceUSDT(selectedToken.address, 500);
                }
             

                const tokenPriceUSDTDecimal = parseFloat(web3.utils.fromWei(tokenPriceUSDT.toString(), 'ether'));
                const amountInUSDT = parseFloat(amount) * tokenPriceUSDTDecimal;

                const gblPriceUSDT = await getUniswapV3PriceUSDT(SETTINGS.gblAddresses[networkId], 10000);
                const gblPriceUSDTDecimal =  parseFloat(web3.utils.fromWei(gblPriceUSDT.toString(), 'ether'));
                const amountGblToBuy = amountInUSDT / gblPriceUSDTDecimal;
                let feePercentage = 1.1;
                if(parseFloat(amountInUSDT) < 100) {
                    feePercentage = 1.5; 
                } else if(parseFloat(amountInUSDT) >= 100 && parseFloat(amountInUSDT) < 250) {
                  feePercentage = 1.4; 
                } else if(parseFloat(amountInUSDT) >= 250 && parseFloat(amountInUSDT) < 750) {
                  feePercentage = 1.3; 
                } else if(parseFloat(amountInUSDT) >= 750 && parseFloat(amountInUSDT) < 1000) {
                  feePercentage = 1.2; 
                } else if(parseFloat(amountInUSDT) >= 1000) {
                  feePercentage = 1.1; 
                } 

                const swapBuySellAmount = amountInUSDT * feePercentage;
                setGblBuyAmount(swapBuySellAmount.toFixed(4));
                setGblSellAmount(swapBuySellAmount.toFixed(4));
                setGblAmountValue(amountGblToBuy.toFixed(4));
                setSwapAvailable(true);


           
            }
        
        }
      };

      const getPriceNetwork = async (tokenAddress) => {

        const routerABI = [
          {
            "constant": true,
            "inputs": [
              {
                "internalType": "uint256",
                "name": "amountIn",
                "type": "uint256"
              },
              {
                "internalType": "address[]",
                "name": "path",
                "type": "address[]"
              }
            ],
            "name": "getAmountsOut",
            "outputs": [
              {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
              }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
          }
        ];
        
       
        const usdtAddress = SETTINGS.usdtAddress[networkId];

        const routerContract = new web3.eth.Contract(routerABI, "0x10ED43C718714eb63d5aA57B78B54704E256024E");
    
    
        const amountIn = web3.utils.toWei('1', 'ether'); // This is for tokens with 18 decimals
    
      
    
        try {
            const amountsOut = await routerContract.methods.getAmountsOut(amountIn, [tokenAddress, usdtAddress]).call();
            
            
            let priceInUSDT = amountsOut[1];
            
            return priceInUSDT;
        } catch (error) {
            console.error('Error fetching price:', error);
            return 999;
        }
    };

      const getUniswapV3PriceUSDT = async (tokenAddress, poolFee) => {

        if(networkId == 56) {
          const p = await getPriceNetwork(tokenAddress);
        
          return p;
        }
        const usdtAddress = SETTINGS.usdtAddress[networkId];
        
        const quoterABI = [
            {
              "inputs": [
                {
                  "internalType": "address",
                  "name": "tokenIn",
                  "type": "address"
                },
                {
                  "internalType": "address",
                  "name": "tokenOut",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "fee",
                  "type": "uint24"
                },
                {
                  "internalType": "uint256",
                  "name": "amountIn",
                  "type": "uint256"
                },
                {
                  "internalType": "uint160",
                  "name": "sqrtPriceLimitX96",
                  "type": "uint160"
                }
              ],
              "name": "quoteExactInputSingle",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "amountOut",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            }
          ]; // Uniswap V3 Quoter ABI
        const quoterContract = new web3.eth.Contract(quoterABI, SETTINGS.quoterAddress[networkId]);
    
        const amountIn = web3.utils.toWei('1', 'ether'); // Assuming the input token has 18 decimals
    
        try {
            // Assuming quoteExactInputSingle is the correct method; adjust as necessary
            const quote = await quoterContract.methods.quoteExactInputSingle(tokenAddress, usdtAddress, poolFee, amountIn, 0).call();
    
    
            // Return the price as a string with up to 6 decimal places
            return quote;
        } catch (error) {
            console.error('Error fetching price:', error);
            return "9999999999"; // Indicative of an error state, adjust as necessary
        }
    };

    const collectIncome = async () => {
    

        setIsLoading(true);
        
        
            try {
                const contract = new web3.eth.Contract(WaweSwapsABI, SETTINGS.waweSwapAddress);
            await contract.methods.claimUsdtReward().send({
                from: selectedAccount,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
              });
            setStakeAmount('');
        } catch (error) {
            console.error('stake failed', error);
            
        
        }
        loadUserData();
        handleCloseCollectModal();
        
        setIsLoading(false);
    }

    const copyRefferalLink = async () => {
      const text = "https://waveswaps.com/?v=2&reff="+selectedAccount;
      navigator.clipboard.writeText(text);
      displayToast('Link coppied!');
    };


    const handleSwap = async () => {
        setIsLoading(true);
        try {
          const contract = new web3.eth.Contract(
            WaweSwapsABI, 
            SETTINGS.waweSwapAddress);
            const fee =  await contract.methods.FEE().call();
            let refAddr = SETTINGS.genessisAddress;
            if(refferalAddress != null){
              refAddr = refferalAddress;
            }
            
            if(selectedToken.symbol === 'BNB') {
                setLoadingNumber(1);
                // For BNB, no need to approve, directly perform the swap
            
    
                // Calculate the BNB amount in Wei
                const bnbAmountInWei = parseFloat(web3.utils.toWei(swapTokenAmount.toString(), 'ether')) + parseFloat(fee);
           
                        
      
                    
    
                        setLoadingStep(1);
                        setLoadingText("Opening WaveSwap V2");

                        console.log("11234143");  
                        console.log(selectedRecycleToken.address);  
                        console.log(refferalAddress);  
                        console.log(refAddr);  
                // Assuming there's a separate function for BNB swaps
                const tx = await contract.methods.buySwapWithETH(
                  selectedRecycleToken.address,
                  refAddr
                ).send({ 
                    from: selectedAccount,
                    value: bnbAmountInWei, // Send BNB along with the transaction
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null  
                });

                displayToast("Swap opened!", tx.transactionHash);
    
            } else {
                setLoadingNumber(1);

                setLoadingStep(1);
                setLoadingText("Approving " + selectedToken.symbol + " transaction.");
    
                  // Approve the contract to spend tokens on behalf of the user
                  const tokenContract = new web3.eth.Contract(ERC20_ABI, selectedToken.address);
                  
               
                  const gblAmountInWei = web3.utils.toWei(gblAmountValue.toString(), 'ether');
                  const amountInWei = web3.utils.toWei(swapTokenAmount.toString(), 'ether');
                  const allowanceToken = await tokenContract.methods.allowance(selectedAccount, SETTINGS.waweSwapAddress).call();
                  if(parseFloat(allowanceToken) < parseFloat(amountInWei)){
                    setLoadingNumber(2);
                    await tokenContract.methods.approve(SETTINGS.waweSwapAddress, amountInWei).send({ 
                        from: selectedAccount,
                        maxPriorityFeePerGas: null,
                        maxFeePerGas: null  });
                      setLoadingStep(2);
                  }
                    
                    
                      
                      setLoadingText("Opening WaweSwap V2");
        

                const tx = await contract.methods.buySwap(
                    selectedToken.address, 
                    amountInWei,
                    gblAmountInWei,
                    selectedRecycleToken.address,
                    refAddr
                ).send({ 
                    from: selectedAccount,
                    value: fee, // Send fee along with the transaction
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null  });

                    displayToast("Swap opened!", tx.transactionHash);
            }
            
        } catch (error) {
            console.error('Swap failed', error);
            console.log('Swap failed');
        } finally {
            // Ensure user data is reloaded and UI is updated accordingly
            loadUserData();
            handleCloseSwapModal();
            setIsLoading(false);
        }
    };
    

    const handleCloseSwap = async (index) => {
  
      setIsLoading(true);
      setLoadingNumber(1);
      setLoadingStep(1);
      setLoadingText("Closing swap.");
        await handleCloseSwapFunction(index);
        setIsLoading(false);
      
    
        loadUserData();
    };

    const handleCloseSwapFunction = async (index) => {
      try {
        const web3 = new Web3(Web3.givenProvider);
        const contract = new web3.eth.Contract(
          WaweSwapsABI,
          SETTINGS.waweSwapAddress
        );
  
    
        
        const withdrawTx = await contract.methods
          .closeSwap(index)
          .send({
            from: selectedAccount,
            value: 0,
            maxPriorityFeePerGas: null, maxFeePerGas: null,
          });
          displayToast('Swap closed!', withdrawTx.transactionHash);
      } catch (error) {
        console.error("An error occurred:", error);
        displayToast(error.message, null, true);
      }
      
    };

    

    const showAvailableTokens = (address) => {
    
        // Construct the URL with the address and amount parameters
        const url = `https://waweswaps.global/api/get_balances.php?address=${address}`;
    
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            return response.text();
          })
          .then(data => {
            var tokenArr = JSON.parse(data);
            var userTokens = [];
    
            for(let i=0; i<tokenArr.length;i++){
              userTokens.push(tokenArr[i].symbol);
            }
    
            var displayTokens = [];
            for(let i=0; i<SETTINGS.tokensData.length;i++){
                if(userTokens.includes(SETTINGS.tokensData[i].symbol)){
                  displayTokens.push(SETTINGS.tokensData[i]);
                }
            }
            setTokens(SETTINGS.tokensData.sort((a, b) => (a.price > b.price ? -1 : 1)));
            setBuyTokens(SETTINGS.buyTokensData);
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
    
    
    };

    const exportSwaps = async () => {
     

          const contractStorage = new web3.eth.Contract(WaweSwapStorageABI, SETTINGS.waweSwapStorageAddress );
    
          let allOpenSwaps = await contractStorage.methods.getAllOpenSwaps().call();
          let csvResult = "";
          let swapsArr = [];
    
          for(let i = 0; i < allOpenSwaps.length; i++){
            let swap = allOpenSwaps[i];
            if(swap[3]=="0x0000000000000000000000000000000000000000") continue;
            const swapAmount  = swap[0];
            const sellAmount   = swap[1];
            const startValue  = swap[2];
            const addr  = swap[3];
            swapsArr.push([swapAmount, sellAmount, startValue, addr]);
    
            csvResult += addr + ";" 
            + parseFloat(web3.utils.fromWei(sellAmount, "ether")).toFixed(6) + ";" 
            + parseFloat(web3.utils.fromWei(startValue, "ether")).toFixed(6) + ";" 
            + parseFloat(web3.utils.fromWei(swapAmount, "ether")).toFixed(6) +  "\n";
    
          
          }
    
        
          
        
          console.log("swapsArr");
          console.log(JSON.stringify(swapsArr));
      
              // generate csv file
              console.log(JSON.stringify(swapsArr));
              const currentDateTime = getCurrentDateTimeString();
              const fileName = `swapV2_export_${currentDateTime}.csv`;
              const blob = new Blob([csvResult], { type: "text/plain" });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
    
    };

const getCurrentDateTimeString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");
  const second = String(now.getSeconds()).padStart(2, "0");

  return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
};


    return (
        <div className="waweSwap">
<InfoModal link="https://docs.waveswaps.com/products-and-features/swap-v2" message="Swap V2 represents a significant leap forward from its predecessor, Swap V1, with a focus on revitalizing less-traded or illiquid tokens through innovative features. By allowing users to purchase swapping tokens on decentralized exchanges (DEX) and allocate them to the Recycle pool, Swap V2 effectively reduces tokens from global circulation. This advancement streamlines the swapping process, enabling users to initiate swaps directly from their MetaMask wallet by providing liquidity in USDT or other mainstream cryptocurrencies to the Swap V2 smart contract. The exit swapping value is determined by the provided liquidity, with a potential increase ranging from 10% to 50% compared to the initial provision." />
    
    <ToastNotification show={showToast} onClose={() => setShowToast(false)} message={toastMessage} url={toastUrl} error={toastError} />
           
        
      


                {isLoading ? (
                          <center><br /> <FontAwesomeIcon icon={faSpinnerThird} spin color="var(--maincolor)"  className='bigLoader' />
                           
                          
                           <p className="smallTitle">
          Step <br /><b>{loadingStep} </b> of <b> {loadingNumber}</b>    <br /> {loadingText} <LoadingDots />
                            <br />
                          </p>
                         </center>
                        ) : (
  

      <div className="row">
       <div className='col-12'>
 

       {selectedAccount &&(<>
          {selectedAccount.toLowerCase() ===
              SETTINGS.ownerAddress.toLowerCase() && (
                <button onClick={exportSwaps}>Export</button>
                
              )}</>
              )}
  
       <div className="card">
        <div className="card-body text-center">
        
          <form>
          {isLoading ? (
                          <div>
                          <center>
                           
                          
                          <p>
                            <b>{loadingStep} / {loadingNumber}</b> <br /> {loadingText}
                          </p>
                          </center>
                        </div>
                          ):(<div>
                            <h4>Revitalize Recycle Tokens</h4>
                           {selectedAccount &&(
 <div className='formBox' style={{width:"100%", backgroundColor:"#0b4182", padding:"20px"}}>
 <label className="form-label" style={{color:"#FFFFFF"}}>Invite others and earn <br />10% from their swaps.</label>
 <button className="mediumButton" type="button" 
   onClick={copyRefferalLink}
   style={{float:"right", marginTop:"15px"}}
   >
   <FontAwesomeIcon icon={faCopy} className="smallIconGray" style={{display:"inline-block", marginRight:"10px"}} />
 Copy link </button>
 </div>
            )}
             <div className='formBox'>
              
              </div>
                               <div className='formBox'>
              <label className="form-label">Liquidity Provision</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                value={swapTokenAmount}
                onChange={handleBuyAmountChange}
              />
              <label className="form-label"><small>Balance: </small> {ercBalance}</label>
              
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" disabled={selectDisabled} className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons[selectedLabel]} alt="" style={{ width: '20px', marginRight: '10px' }} />{selectedLabel}
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdownMenuStyle'>

        {buyTokens.map((token) => (
          <Dropdown.Item key={token.address} onClick={() => handleSelect(token)}>
            <img src={SETTINGS.tokenIcons[token.symbol]} alt="" style={{ width: '20px', marginRight: '10px' }} />{token.symbol}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
</div>

              </div>
              <div className="">
              <center>
              <i className="smallIconGray">
                    <FontAwesomeIcon icon={faArrowsDownToLine}  />
                  </i>
              </center>
            </div>

     
            <div className="mb-3">
                            
               
                      <div className='formBox'>
              
<div className='row'>



  <div className='col-6 col-md-6 col-lg-6'>
  <label className="form-label">Referral address <small>(Optional)</small></label>
  <input
                id="refferalAddress"
                type="text"
                className="form-control"
                placeholder="0x.. (Optional)"
                value={refferalAddress}
                onChange={handleRefferalAddressChange}
                style={{fontSize:"15px"}}
              />
  </div>
  <div className='col-6 col-md-6 col-lg-6'>
  <label className="form-label">Recycle token</label>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" disabled={selectDisabled} className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons[selectedLabelRecycle]} alt="" style={{ width: '20px', marginRight: '10px' }} />{selectedLabelRecycle}
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdownMenuStyle'>

        {tokens.map((token) => (
          <Dropdown.Item key={token.address} onClick={() => handleSelectRecycle(token)}>
            <img src={SETTINGS.tokenIcons[token.symbol]} alt="" style={{ width: '20px', marginRight: '10px' }} />{token.symbol}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
</div>

              </div>
               
                  
              {selectedRecycleToken && ( <>
              <div className='formBox'>
                <small className="smallTitle">You send</small><br /><br />
                      <div className='row'>
                      <div className='col-1'><img src={SETTINGS.tokenIcons[selectedToken.symbol]} alt="" style={{ width: '15px' }} /></div>
                            <div className='col-5'><label className="form-label">To liquidity: </label></div>
                            <div className='col-6'><label className="form-label"> {swapTokenAmount} <small>{selectedToken.symbol}</small></label></div>
                            </div>   
                        
                    
                         
                      </div>
              <div className='formBox'>
                <small className="smallTitle">Target exit</small><br /><br />
                      
                            <div className='row'>
                      
                            <div className='col-1'><img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px'}} /></div>
                            <div className='col-5'><label className="form-label">Income: <TooltipInfoReverse cur="USDT" title='Swapping income:' /> </label></div>
                            <div className='col-6'><label className="form-label"> {gblSellAmount} <small>USDT</small></label></div>
                                 </div>   
                            <div className='row'>
                      
                            <div className='col-1'><img src={SETTINGS.tokenIcons["VGBL"]} alt="" style={{ width: '15px'}} /></div>
                            <div className='col-5'><label className="form-label">Voting power: </label></div>
                            <div className='col-6'><label className="form-label"> {gblAmountValue} <small>VGBL</small></label></div>
                                 </div>   
                    
                         
                      </div>
              
                      </>)}
            </div>

           
           
            </div>)}
           
            <br />
            <div className="d-grid gap-2">
            <small className="smallText text-left" style={{paddingBottom:"10px"}}>
            The referral address used for the first time will remain permanently linked to your current wallet.<br />
            When a referral address is not linked or added, the swap will automatically use and allocate the Genesis referral address.<br />
              By clicking the button "Swap", you agree with WaveSwaps <a href="https://docs.waveswaps.com/corporate/terms-and-conditions" target="_blank">Terms and Conditions.</a>

                </small>
                </div>
            <div className="d-grid gap-2">
            {swapAvailable ? ( <div>
                {isLoading ? (
                          <SpinnerBox><Spinner animation="border" variant="primary" /></SpinnerBox>
                        ) : (<button className="large-button-blue" type="button" onClick={handleSwap}>Swap</button>)}
                        </div>):(
               <div>
                
               <button className="large-button-blue-disabled" type="button" >Swap</button>
               <center><div className="error-text error-text" >{errorText}</div></center>
               </div>
            )}

<small className="smallText text-left" >
Swapping income is distributed when enough total BNB GAS has accumulated to execute the distribution function within the V2 smart contract.
                </small>
            </div>
            
          </form>

        </div>
      </div>

       </div>
       <div className='col-12 '>
       <br />
       <div className="row" style={{textAlign:"center"}}>


       <div className="col-3 col-lg-4"><small className='smallTitle'>Income:</small></div>
<div className="col-6 col-lg-4"><img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '20px', marginRight: '10px', marginTop:"-3px" }} /><small> {unclaimedRewards}</small> <small className='smallText'>USDT</small></div>
<div className="col-3 col-lg-4"><button type="button" className="mediumButton" style={{marginTop:"0px"}}  onClick={handleShowCollectModal}>Claim</button></div>


</div>



<Accordion defaultActiveKey="0">

{userSwaps.map((swap, index) => (
  <Card key={index}>
      <Card.Header>
  <div className="row ">
  <div className="col-5">
    <small className='smallTitleSmall' style={{ lineHeight: '30px', marginTop: "5px" }}> <img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px', marginRight: '10px', marginTop:"-3px" }} /> {swap.soldAmount} <small className='smallText'> USDT</small></small>
    </div>

  <div className="col-1">

   
    </div>
    <div className="col-5">
    <small className='smallTitleSmall' style={{ lineHeight: '30px', marginTop: "5px" }}> <img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px', marginRight: '10px', marginTop:"-3px" }} />{swap.maxSellAMount} <small className='smallText'> USDT</small> </small>
    </div>
  <div className="col-1"> <ContextAwareToggle eventKey={String(index)}></ContextAwareToggle></div>
    
    <div className="col-12">
<ProgressBar now={swap.soldAmount} min={0} max={swap.maxSellAMount} striped variant="info" animated className='swapProgress' ></ProgressBar>

</div>
</div>
  </Card.Header>


  <Accordion.Collapse eventKey={String(index)}>
    <Card.Body>

    <div className="row">
                  
                  <div className="col-12 col-lg-4">
                    <label className="form-label">Swapping </label><br />
               <small> <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.amount} GBL</small>
                    <br />
                    <br />
                    </div>
                    <div className="col-12 col-lg-4">
                    <label className="form-label">Outstanding </label><br />
               <small> <img src={SETTINGS.tokenIcons["USDT"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.sellAmount} USDT</small>
               <br />
               <br />
                    </div>
                    <div className="col-12 col-lg-4">
                    <label className="form-label">Earned VGBL  </label><br />
               <small> <img src={SETTINGS.tokenIcons["VGBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />{swap.amount} VGBL</small>
               <br />
               <br />
                    </div>
                
              
               
                    
                  
                  </div>

    </Card.Body>
  </Accordion.Collapse>
</Card>
))}

      
    
    </Accordion>
       </div>
     
    
      </div>
  
    

 
                        )}



   
<div className="row">
            
          
            <div className="col-12 col-lg-6 text-center">
            <Modal show={showCollectModal} onHide={handleCloseCollectModal}>
                    <Modal.Header closeButton>
                      <Modal.Title><center>Claim income</center></Modal.Title>
                      
                    </Modal.Header>
                    <Modal.Body>
            <div className="card ">
                  <div className="card-body text-center">
                   
                    <strong>Available USDT income:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewards}
                    disabled
                />
                    <strong>Amount to claim:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewardsNet}
                    disabled
                />
                    <strong>Claim income processing fee:</strong>
           
                <input
                    type="text"
                    value={unclaimedRewardsFee}
                    disabled
                />
                
                <br />

                <br />
                {unclaimedRewards > 0 ? (<>
                {isLoading ? (
                          <SpinnerBox><Spinner animation="border" variant="primary" /></SpinnerBox>
                        ) : (<button className="large-button-blue" type="button" onClick={collectIncome}>Claim</button>)}
                        </> ) : (null)}
                        
           
            </div>
            </div>
            <br />
            <small className='smallText'>
                  <b> Swapping income claiming fee requests:</b>
<ul >
<li>Up to 99 USDT: 2%</li>
<li>From 100 to 999 USDT: 1%</li>
<li>1000 USDT onward: 0.5%</li>
</ul>
                  </small>
            </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                  </Modal>

            </div>

       

     


        
</div>

       
        
        </div>
    );
}

export default WaweSwapRecycle;
