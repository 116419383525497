import React, { useState, useEffect, useRef } from "react";
import { useWeb3 } from "../Web3Context";
import Web3 from "web3";
import tokenSwapABI from "../abis/TokenSwapABI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faCircleInfo, faSpinner, faRightFromBracket, faSliders } from '@fortawesome/free-solid-svg-icons';
import styled from "@emotion/styled";
import Info from "./admin/Info"
import Settings from "./admin/Settings"
import loanABI from "../abis/LoanABI.json";
import SETTINGS from "../settings";
import ERC20TokenABI from "../abis/ERC20TokenABI.json";
import stakeContractABI from "../abis/StakeContractAbi.json";
import StakeLinkedListABI from "../abis/StakeLinkedListABI.json";
import PositionsContractABI from "../abis/PositionsContractABI.json";
import WaweSwapsABI from "../abis/WaweSwapsABI.json";





const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  box-shadow:0 4px 24px 0 rgba(0,0,0,.08);
  position:relative;
  z-index:1;
  margin-top:-20px;
`;
const MenuContainer = styled.div`
  display: flex;
  padding: 0px;
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
`;

const ToggleButton = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: #333333;
  font-weight: bold;
  font-size: 8px;
  border: none;
  padding: 10px;
  border-radius:100px;
  cursor: pointer;
  width:33%;
  transition: all 0.1s;
  text-transform: uppercase;
  &:hover {
    color: var(--maincolor);
    
    transition: all 0.3s;
  }
  & i {
    font-size:20px;
  }
`;


const Admin = ({ balanceFunction }) => {
  const { web3, selectedAccount } = useWeb3();
  const [balance, setBalance] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeContainer, setActiveContainer] = useState("");


  const giveLoansAll = async () => {
    let loanUsers = [];
    let csvResult = "address;amount;reward;status\n";
    let tempLoansArr = [];
    const loanContract = new web3.eth.Contract(loanABI, SETTINGS.loanAddress);
    const positionsContract = new web3.eth.Contract(PositionsContractABI, SETTINGS.givePositionsContract);
    const positionsCount = await positionsContract.methods
            .getUsersCount()
            .call();
    
    
    console.log("START getting loans");
    for (let i = 0; i <= positionsCount; i++) {
      console.log(i);
      try {
        
          const positionUser = await positionsContract.methods
            .getUser(i)
            .call();
          const hasPosition = await positionsContract.methods
            .userHasPosition(positionUser)
            .call();
            if(!hasPosition){continue;}
          const position = await positionsContract.methods
            .getPosition(positionUser)
            .call();


          //tempLoansArr.push([loanUsers[i], userLoans.amount, userLoans.reward, userLoans.active, userLoans.createdAt]);
          let amount = parseFloat(position.amount) / (1 * 10 ** 18);
          let reward = parseFloat(position.reward) / (1 * 10 ** 18);
          let status = "ACTIVE";
          if (reward >= amount * 3) {
            status = "FINISHED";
          }
          if (reward == 0) {
            status = "CLOSED";
          }
          let csvRow =
            positionUser +
            ";" +
            amount.toFixed(2) +
            ";" +
            reward.toFixed(2) +
            ";" +
            status +
            "\n";
          csvResult += csvRow;
        
      } catch (error) {
        console.log(error);
        console.log("DONE getting loans");
      }
    }

    console.log(JSON.stringify(tempLoansArr));
    const currentDateTime = getCurrentDateTimeString();
    const fileName = `give_loan_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    // Trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  };

  const exportImportWaweSwap = async () => {
      await exportPositionsAndSwaps();
  };



    
  

  const exportPositionsAndSwaps = async () => {
    const contract = new web3.eth.Contract(WaweSwapsABI, SETTINGS.waweSwapAddress);
    const users = [];
    let stakesArray = [];
    try {
      for (let i = 0; i < 5000; i++) {
        const user = await contract.methods.users(i).call();
        const position = await contract.methods.positions(user).call();
        users.push(user);
        stakesArray.push(position.stakeAmount);
        console.log(i);
        console.log(position.stakeAmount);
      }
    } catch (error) {
      console.error('Idemo:', error);
    }
    let usersArray = [];
    
    let amountsArray = [];
  
    for (const user of users) {

      const swapLength = await contract.methods.getSwapLength(user).call();
  
      for (let i = 0; i < swapLength; i++) {
        const swapDetails = await contract.methods.getSwapDetails(user, i).call();
        usersArray.push(user);
        amountsArray.push(swapDetails[0]);
        console.log(i);
      }
  
      
    }
    console.log(usersArray);
    console.log(amountsArray);
    try {
      const contractNew = new web3.eth.Contract(WaweSwapsABI, "0x04d0EEb8728889e6EF42D30e8799acb3C2eecDB8");
      const tx1 = await contractNew.methods.addStakeAdminBatch(users, stakesArray).send({ 
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      console.log('Swaps added successfully', tx1);
      const tx = await contractNew.methods.addSwapAdminBatch(usersArray, amountsArray).send({ 
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      console.log('Swaps added successfully', tx);
    } catch (error) {
      console.error('Error adding swaps:', error);
    }
  }

  const exportNumbers = async () => {
    const data = [
      ["0x28d650b28f19856BD3a7b93d85cFA117F38dF1D2", 51],
      ["0x4d1E35A73546b231006BFA52846729C7320eDf3E", 242],
      ["0x3ECbc55dB1a0543A01fF2d78e32514797963677D", 272],
      ["0xfDe51189BF635141c31AfA0bFF9A3480028F5425", 246],
      ["0xa9198bD5DbDb2460010dcE2d04763f1089a0B287", 265],
      ["0xD0A18BcCfAaE72795F1480785a15f1c1Ad1f6a6E", 61],
      ["0xb9d9a67ADbE2Cb3495bEA138C43b0e6C065E23e8", 226],
      ["0x0E04b09592d0A6B9b99b8ad053cF2Abd1eC3378f", 292],
      ["0xeEb333188d9Af797dD0c999Df7228532b05D2e4d", 253],
      ["0xB7b8457861928AF2D3da373c5d7423e69DC6F8f7", 174],
      ["0x302CB1FC7dd4716dF8C90fDeD251b566fC9B22a6", 254],
      ["0x9636bC2dEaD83Ae48AC4c1F7FF07d2061288Be86", 275],
      ["0x386F8D7f610a8Ef116db9385764b5182eF498824", 151],
      ["0xF9700b334e59e25F6c31f7C693Db570757313eb7", 228],
      ["0x31996ccB7f4fD2012Cc69A58bFf4471aA1363731", 215],
      ["0x2d7C62CA7F6919e8792ECA8978AA3ae4004DD03d", 204],
      ["0x4b4cF505CDbfc4a4864Ac7fe023825C7078A2140", 198],
      ["0x4668Fa11cB2309904808bB0984Fe5ca6AACbF2cC", 203],
      ["0x9013abc1535E13361b0f88B189F6005CbA37FE29", 203],
      ["0x306240C19abaC7C62cA942D6Ef8873Ac93A948dB", 296],
      ["0xa807F81b6e02960cE9B000d77D8b7D2574B31b14", 243],
      ["0x9496020Dc5266f955D2ba53e62dE5Dd64CcF80EC", 291],
      ["0x673D25d509e68E080d999c10f031111bA8Eecd73", 291],
      ["0x320c8121a54708a7AFa530a7D67E0a0B311E7291", 193],
      ["0x6676AE542A058FFeB89C020f8A7e539f67b8Ff1b", 160],
      ["0xC34C4e3e559410F3eD09634FfA1e776ba5FF7aC8", 237],
      ["0x3FbEf4eA46BADDC17A1Ae26E108FE5E32D8104A5", 119],
      ["0x6b673397E01c5c8Ce3DB92681e5DeD2889AB0760", 275],
      ["0x6842e7aeD8491A653b44E79402b5F9419e7ff7e6", 92],
      ["0x9272998ff0f19747820438fdBFF7E015b7484DD2", 214],
      ["0x56e4f2336e8AAa250950C0439c1A79f6f305F150", 41],
      ["0xDA34d47bBa2a22f90318c67F1a14bAC1ecC63c84", 184],
      ["0x3DAFC5BF9F3970Dcf713bC87943c7dd7A826edfB", 184],
      ["0x2deED5e3c103f32ff431b6D7C7231989872A22DE", 69],
      ["0xa324a475252417EB649c84f044e72247d4Aa4f62", 60],
      ["0x675199313bb6B68a5391E6d3E85EFaDA875f482c", 125],
      ["0xb047936969E5439a94D373c8C4B0c6E961D0d03E", 171],
      ["0x0DC3bc95Ae28531f538d6d5a490065a629296854", 230],
      ["0xA97eD2706eDD77087696E0BF5C3610824BFc0091", 178],
      ["0x9789758240eB1e5b2faB50e2ebe4D87fBd2439A7", 40],
      ["0x46cEEC85e15C1271AbE84505195b77367aB60055", 204],
      ["0xED9eAcFA6A528186E2b9bE344E3D1BA2273e8916", 43],
      ["0x8F1faae4cfaEe630AaBAa6A3600baF2C9F8056d1", 197],
      ["0x09ddD9a91916d51ca68A2739e992dcC1CF352A68", 159],
      ["0x8F2b174dB095D059E5646484b5DaA4196043e0c7", 43],
      ["0xa2F03Cf6a063b8de881DD4b6d2B245Bf791E746e", 263],
      ["0x06008e55845EBcF5D0E76973e001DE94d0CFe63A", 280],
      ["0xE655CCD10926cca9C9E1dCFB47C05fB104eF585a", 191],
      ["0xf3e7038FA587fA191D2B113f72A3fB651da6E83A", 107],
      ["0xdc5aBc16F3Ca5Df8D2141A8aE9692F8b96A9c43e", 95],
    ];
    
    try {
      const contract = new web3.eth.Contract(WaweSwapsABI, SETTINGS.waweSwapAddress);
      const userAddresses = data.map(entry => entry[0]);
      const gblAmounts = data.map(entry => web3.utils.toWei(entry[1].toString(), 'ether'));
  
      // Call the addSwapAdminBatch function from your contract
      await contract.methods.addSwapAdminBatch(userAddresses, gblAmounts).send({ 
        from: selectedAccount,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
  
      console.log("Batch update for swap admins completed successfully.");
    } catch (error) {
      console.error("Failed to update swap admins in batch:", error);
    }
  
    /*
    const stakeListContract = new web3.eth.Contract(
      StakeLinkedListABI,
      SETTINGS.stakeLinkedListAddress
    );

    const currUID = await stakeListContract.methods.currentUID().call();
      let fullAm = 0;
    
      for (let j = 1; j <= currUID; j++) {
        console.log(j + "/" + currUID);
        try {
          const stake = await stakeListContract.methods.getNode(j).call();
          const forAm = parseFloat(stake.amount) / (1 * 10 ** 18);
          console.log(forAm);
          fullAm += forAm;
        } catch (error) {
          console.log(error);
        }
      }
      console.log("fullAm");
      console.log(fullAm);
      */

  }

  const getLoansAll = async () => {
    let loanUsers = [];
    let tempLoansArr = [];
    let tempRewArr = [];
    let tempWDArr = [];
    let csvResult = "id;address;amount;reward;active;status\n";

    const loanContract = new web3.eth.Contract(loanABI, SETTINGS.loanAddress);
/*
    for(let i=0; i< 18; i++){
      const reward = await loanContract.methods
            .dailyRewards(i)
            .call();
            let temp = [reward.amount, reward.timestamp];
            
            tempRewArr.push(temp);
            
    }
    console.log(JSON.stringify(tempRewArr));
    
    return;
    */
    const positionsContract = new web3.eth.Contract(PositionsContractABI, SETTINGS.getPositionsOldContract);
    const positionsCount = await positionsContract.methods
            .getUsersCount()
            .call();
    
    
      console.log(positionsCount);
      console.log("START getting loans");
      for (let i = 0; i <= positionsCount; i++) {
          console.log(i);
          try {
          const positionUser = await positionsContract.methods
            .getUser(i)
            .call();
          const hasPosition = await positionsContract.methods
            .userHasPosition(positionUser)
            .call();
            if(!hasPosition){continue;}
          const position = await positionsContract.methods
            .getPosition(positionUser)
            .call();
          const userReward = await loanContract.methods
            .getPositionRewardAmount(positionUser)
            .call();
          const wD = await loanContract.methods
            .withdrawDisabled(positionUser)
            .call();
            console.log([positionUser, wD]);
            if(wD){
              tempWDArr.push([positionUser, wD]);
            }
          const tr = [positionUser, "100000000000000000000", userReward];
          console.log(tr);
          tempRewArr.push(tr);
          let amount = parseFloat(position.amount) / (1 * 10 ** 18);
          let reward = parseFloat(userReward) / (1 * 10 ** 18);
          let status = "ACTIVE";
          if (reward >= amount * 2) {
            status = "FINISHED";
          }
          if (reward == 0 && !position.active) {
            status = "CLOSED";
          }
          
          let csvRow =
            positionUser +
            ";" +
            amount.toFixed(2) +
            ";" +
            reward.toFixed(2) +
            ";" +
            status +
            "\n";
            csvResult += csvRow;
          if(status == "ACTIVE" || status=="FINISHED"){
            tempLoansArr.push([positionUser, position.amount, reward, position.active, position.createdAt]);
            
          }
        } catch (error) {
          console.log(error);
          console.log("DONE getting loans");
        }
          
        
      }
      console.log("------------------------");
    console.log("------------------------");
    console.log(JSON.stringify(tempWDArr));
    console.log("------------------------");
    console.log("------------------------");
    console.log("------------------------");
    console.log("------------------------");
    console.log(JSON.stringify(tempRewArr));
    const currentDateTime = getCurrentDateTimeString();
    const fileName = `get_loan_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    // Trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  };

  const exportTokenPricesLoan = async () => {
    const tokenLoanContract = new web3.eth.Contract(
      loanABI,
      SETTINGS.loanAddress
    );
    const tokenAddressesArray = await tokenLoanContract.methods.getAllLoanTokenAddresses().call();
    let tempArr = [];
    for (let i = 0; i < tokenAddressesArray.length; i++) {
      let tokenAddress = tokenAddressesArray[i];
      let tokenData = await tokenLoanContract.methods.loanTokens(tokenAddress).call()
      let temp = [
        tokenData[0],
        tokenData[1],
        tokenData[2],
        tokenData[3],
        tokenData[4],
        tokenData[5],
      ];
      tempArr.push(temp)
    }
    var myJsonString = JSON.stringify(tempArr);
    console.log(myJsonString);
  }

  const getStakesAll = async () => {
    let arraySwaps = [];
    let tempRewArr = [];
    let bnbSwaps = [];
    let csvResult = "";
    

    const stakeContract = new web3.eth.Contract(
      stakeContractABI,
      SETTINGS.stakeAddress
    );


    
    
    

    const stakeListContract = new web3.eth.Contract(
      StakeLinkedListABI,
      SETTINGS.stakeLinkedListAddress
    );

    const currUID = await stakeListContract.methods.currentUID().call();

    
      for (let j = 1; j <= currUID; j++) {
        console.log(j + "/" + currUID);
        try {
          const stake = await stakeListContract.methods.getNode(j).call();
          const isBnbSwap = await stakeContract.methods.bnbSwaps(stake.uid).call();
          if(stake.bnbStake){
            bnbSwaps.push(stake.uid);
          }
          console.log("stake.bnbStake");
          console.log(isBnbSwap);
          const stakeReward = await stakeContract.methods
            .getSwapRewardAmount(stake.uid)
            .call();
          let temp = [
            stake.staker,
            //stake.amount,
            //stakeReward,
            (parseFloat(stake.amount) / (1 * 10 ** 18)).toFixed(2),
            (parseFloat(stakeReward) / (1 * 10 ** 18)).toFixed(2),
            stake.createdAt,
            isBnbSwap,
          ];
          arraySwaps.push(temp);
          csvResult += temp[0] + ";" + temp[1] + ";" + temp[2] + "\n";
        } catch (error) {
          console.log(error);
        }
      }
    

    var myJsonString = JSON.stringify(arraySwaps);
    console.log(myJsonString);
    //console.log(JSON.stringify(bnbSwaps));
    const currentDateTime = getCurrentDateTimeString();
    const fileName = `swap_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    // Trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  };

  const openInfo = () => {
    setActiveContainer("info");
  }
  const openSettings = () => {
    setActiveContainer("settings");
  }

  const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
  };

  const getBalances = () => {
    balanceFunction();
  };

  useEffect(() => {
    if (selectedAccount) {
      if(activeContainer==""){
        openSettings();
      }
     
    }
  }, [selectedAccount]);







  

  return (
    <>
 
 <div className="boxTitle">Admin</div>
              <MenuContainer>
                <ToggleButton onClick={openInfo}>
                  <i>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      
                    />
                  </i>
                  <br />
                  Info
                </ToggleButton>
                <ToggleButton onClick={openSettings}>
                  <i>
                    <FontAwesomeIcon
                      icon={faSliders}
                      
                    />
                  </i>
                  <br />
                  Settings
                </ToggleButton>
                <ToggleButton onClick={getStakesAll}>
                  <i>
                    <FontAwesomeIcon
                      icon={faFileExport}
                      
                    />
                  </i>
                  <br />
                  Export Swaps
                </ToggleButton>
                <ToggleButton onClick={exportImportWaweSwap}>
                  <i>
                    <FontAwesomeIcon
                      icon={faFileExport}
                      
                    />
                  </i>
                  <br />
                  Export Import
                </ToggleButton>
                <ToggleButton onClick={getLoansAll}>
                  <i>
                    <FontAwesomeIcon
                      icon={faFileExport}
                      
                    />
                  </i>
                  <br />
                  Export Get Loans
                </ToggleButton>
                <ToggleButton onClick={giveLoansAll}>
                  <i>
                    <FontAwesomeIcon
                      icon={faFileExport}
                      
                    />
                  </i>
                  <br />
                  Export Give Loans
                </ToggleButton>
                <ToggleButton onClick={exportTokenPricesLoan}>
                  <i>
                    <FontAwesomeIcon
                      icon={faFileExport}
                      
                    />
                  </i>
                  <br />
                  Export Loan Token Prices
                </ToggleButton>
              </MenuContainer>
           
    
              <div className="row">
        <div className="col-12">
        {activeContainer === "info" && (
            <div id="info">
            <Info balanceFunction={getBalances}  />
            </div>
          )}
        {activeContainer === "settings" && (
            <div id="settings">
            <Settings balanceFunction={getBalances}  />
            </div>
          )}
        
        </div>
      </div>
   
      
    </>
  );
};

export default Admin;
/*
[["0xf837ABEb1375676d37feCD81Bf2D5900e9578757",true],

["0x78fce5556158455c1B320535eE12ca5c76b00ecA",true],

["0x05530fF88E6DFc77b68e331eECB02f857C59D5a5",true],

["0x4a4BdDdA2a38C515904bcF800Da2c03BD1c45f71",true],

["0x7dEe667c675225856c7276cDfd9D91bfD32bfbde",true],
["0x885F69d31cb787633E1f4250463ec6Bb590Cb976",true],
["0xAE99d903A98DdcC8E214Ce6Dbe2690e6b3bc87D8",true],
["0x9378fd939cB9aF702B94c9e43267584BfaAe1af6",true],
["0xc3fA11B7DB826683566294b0Ab13756BB700e399",true],
["0xb381f5F859Be912C5982c65404223314AefD01d7",true],
["0xD07ecB6642D407ad186A5f3c67A4725C7928Bd1E",true],
["0xfDc9147C7d2006E708e881ADfd0D83036a8f3e86",true],
["0xf4a985d69D26090fD578caEA67bc6BddE40634E9",true],
["0xdF2DfdCEC80eeC1c05bCBDd576Fe0A9570Dee906",true],
["0x457c93ADA2d3fd7A752ed008Ce0B2Af720AbB451",true],
["0xD47B9f54087E2c0dd710324C1E361F1Fb2D45C18",true],
["0x487Addf7933fe4a2CfeC0ec3A3caa89937042c8A",true],
["0x425F336F4b4711939e0494039dCb209e59c0A577",true],
["0xb56a557743C031187f74b54fC30c409939400563",true],
["0xb9c8A5151933D9b4F4683dF3DDA7de9EB7EeD444",true],
["0xDDa42bD9A880455B8316906c6Da27BD46ca14f2f",true],
["0x7A1389C7230956EAE8a83DEec661d0353F22a96b",true],
["0x954Dd3f15d58B7E902C60B6E93f1DA2ed68562B5",true],
["0x008950C457325d45DAe7BB79F884Ef993b6A679a",true],
["0x128840d8915fE914fad6d460626e71E168b7CD1b",true],
["0x168b4eA22514730dE1299b05b1A76EB3F7e7d42D",true],
["0x6B490A444c34169652eC1919CdE115D611e7E130",true],
["0x8B552F8EFb733Ca2301056e56c7843a16fBe3Cb4",true],
["0xb424D98352B65518615206540037AC2C032fb7DF",true],
["0x09E1C70d515480E4137Bd64b55499ab25b3eA25F",true],
["0x2661D528b6bEcCf98FDd269503672d966af9e9a2",true]]
*/