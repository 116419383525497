import React, { useRef, useState } from 'react';
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCopy,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";
import { faClone} from '@fortawesome/pro-thin-svg-icons'
import {
  faPowerOff
} from "@fortawesome/pro-thin-svg-icons";

import ToastNotification from './ToastNotification';

const WalletBoxColored = styled.div`
  display:inline-block !important;
  background-color: rgba(255,255,255,0.4);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.08);
  color: #555555;
  font-weight: bold;
  font-size: 11px;
  border: none;
  padding: 5px 5px 5px 5px;
  border-radius:100px;
  width:100%;
  text-align:left;
`;

const WalletBox = styled.div`
  display:inline-block !important;
  background-color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.08);
  color: #FFFFFF;
  font-weight: bold;
  font-size: 0.7rem;
  border: none;
  padding: 5px;
  border-radius:100px;
  width:100%;
  text-align:center;
  margin-bottom:5px;
`;
const TextTitle = styled.div`
  display:inline-block !important;
  color: #333;
  font-size: 0.7rem;
  margin-right:15px;

`;
const TextTitleColored = styled.div`
  display:inline-block !important;
  color: #ffffff;
  font-size: 0.7rem;
  margin-right:15px;

`;

function TruncatedText({ text, networkName, disconectFunction,  maxLength, title, colored=false }) {
  const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength / 2)}...${text.slice(-1 * (maxLength / 2))}` : text;
  const copyButtonRef = useRef();
  const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Address coppied.");



  return (
  <>
      <ToastNotification show={showToast} onClose={() => setShowToast(false)} message={toastMessage} />
    {!colored && <WalletBox><TextTitle>{title} </TextTitle>{truncatedText} </WalletBox> } 
    {colored && <WalletBoxColored>
      <div style={{float:"left", marginTop:"3px", marginLeft:"40px"}}>
      <TextTitle>{title} </TextTitle>
      <span>{truncatedText} </span>
      <button 
      className='copyButton'
        onClick={() => {navigator.clipboard.writeText(text); setShowToast(true);}}>
          <FontAwesomeIcon icon={faClone}  />
        </button> 
        </div>
       <div className='networkBox'>
       <div className="ring-container">
    <div className="ringring"></div>
    <div className="ring-circle"></div>
</div>
{networkName}
<button 
      className='copyButton'
        onClick={() => {disconectFunction()}}>
          <FontAwesomeIcon icon={faPowerOff}  />
        </button> 
       </div>

     
      </WalletBoxColored> } 
  </>
  );
}

export default TruncatedText;