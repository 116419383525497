import React, { useState, useEffect, useContext } from 'react';
import { useWeb3 } from "../Web3Context";
import Web3 from 'web3';

import SETTINGS from "../settings";
import WaweSwapsABI from "../abis/WaweSwapsV3ABI";
import WaweSwapStorageABI from "../abis/WaweSwapStorageV3ABI";
import { ERC20_ABI } from "../abis/erc20";
import styled from "@emotion/styled";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { parse } from '@fortawesome/fontawesome-svg-core';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingDots from "./LoadingDots";
import {faCaretDown,faCaretUp} from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird, faArrowsDownToLine, faCircleInfo, faCopy } from '@fortawesome/pro-thin-svg-icons'
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { set } from 'react-ga';


function Stats({swaps}) {
    const { web3, selectedAccount, networkId, networkSymbol, networkNames, waweSwapV3Addresses, waweSwapStorageV3Addresses, waweSwapV3BuyTokens, networkIcons } = useWeb3();
   
  const [loading, setLoading] = useState(false);
  const [totalSellAmount, setTotalSellAmount] = useState(0);
  const [fullSellAmount, setFullSellAmount] = useState(0);
  const [totalTokenAmount, setTotalTokenAmount] = useState(0);
  const [fullTokenAmount, setFullTokenAmount] = useState(0);
  const [userFullTokenAmount, setUserFullTokenAmount] = useState(0);
  const [userFullSellAmount, setUserFullSellAmount] = useState(0);
  const [userTotalTokenAmount, setUserTotalTokenAmount] = useState(0);
  const [userTotalSellAmount, setUserTotalSellAmount] = useState(0);






 
    
    
    

    useEffect(() => {
      if (selectedAccount) {
        
        firstLoad();
        
        
    }
        
    }, [selectedAccount, networkId, swaps]);

      const firstLoad = async () => {
        await loadStats();
        
         
      
        
      }

   


        const loadStats = async () => {
          try {
        
             
              const contract = new web3.eth.Contract(WaweSwapsABI, waweSwapV3Addresses[networkId]);
              const contractStorage = new web3.eth.Contract(WaweSwapStorageABI, waweSwapStorageV3Addresses[networkId]);

              
              const TOTAL_SELL_AMOUNT = await contractStorage.methods.TOTAL_SELL_AMOUNT().call();
              const FULL_SELL_AMOUNT = await contractStorage.methods.FULL_SELL_AMOUNT().call();
              const TOTAL_TOKEN_AMOUNT = await contractStorage.methods.TOTAL_TOKEN_AMOUNT().call();
              const FULL_TOKEN_AMOUNT = await contractStorage.methods.FULL_TOKEN_AMOUNT().call();
              const USER_FULL_TOKEN_AMOUNT = await contractStorage.methods.fullTokenAmounts(selectedAccount).call();
              const USER_FULL_SELL_AMOUNT = await contractStorage.methods.fullSellAmounts(selectedAccount).call();
              let USER_TOTAL_TOKEN_AMOUNT = 0;
              let USER_TOTAL_SELL_AMOUNT = 0;
     
                 // Assuming you know the decimals here; if not, fetch dynamically from the contract
                 let tokenDecimals = 6; // Defaulting to 18 for demonstration; adjust as needed
                 if(networkId == 56){
                  tokenDecimals = 18;
                 }
              
                 setTotalSellAmount(parseFloat(web3.utils.fromWei(TOTAL_SELL_AMOUNT, 'mwei')).toFixed(2));
                  setFullSellAmount(parseFloat(web3.utils.fromWei(FULL_SELL_AMOUNT, 'mwei')).toFixed(2));
                  setTotalTokenAmount(parseFloat(web3.utils.fromWei(TOTAL_TOKEN_AMOUNT, 'ether')).toFixed(2));
                  setFullTokenAmount(parseFloat(web3.utils.fromWei(FULL_TOKEN_AMOUNT, 'ether')).toFixed(2));
                  setUserFullTokenAmount(parseFloat(web3.utils.fromWei(USER_FULL_TOKEN_AMOUNT, 'ether')).toFixed(2));
                  setUserFullSellAmount(parseFloat(web3.utils.fromWei(USER_FULL_SELL_AMOUNT, 'mwei')).toFixed(2));

    
             for (let i = 0; i < swaps.length; i++) {
              const swap = swaps[i];
             
              USER_TOTAL_TOKEN_AMOUNT += parseFloat(swap.amount);
              USER_TOTAL_SELL_AMOUNT += parseFloat(swap.sellAmount);
             }
             setUserTotalTokenAmount(USER_TOTAL_TOKEN_AMOUNT.toFixed(2));
             setUserTotalSellAmount(USER_TOTAL_SELL_AMOUNT.toFixed(2)) ;
      

            } catch (error) {
              console.error('Error fetching swap data:', error);
            }
            return [];
          };

    
 if(!selectedAccount || networkId != 137){
return (<></>); 
}
 


    return (
      <div className='' >
   <br/>
      <div className=' text-center'>
      <h6 className='card-title'>Statistics</h6>
      <div className="row">
      <div className="col-md-3"><small className='statsTitle'>Total started</small>{fullSellAmount} USDT </div>
      <div className="col-md-3"><small className='statsTitle'>Total GBL</small>{fullTokenAmount} GBL </div>
      <div className="col-md-3"><small className='statsTitle'>Active</small>{totalSellAmount} USDT </div>
      <div className="col-md-3"><small className='statsTitle'>Active GBL</small>{totalTokenAmount} GBL </div>
      
        </div>
        <hr />
      <div className="row">
      <div className="col-md-3"><small className='statsTitle'>My Started</small>{userFullSellAmount} USDT </div>
      <div className="col-md-3"><small className='statsTitle'>My GBL</small>{userFullTokenAmount} GBL </div>
      <div className="col-md-3"><small className='statsTitle'>My Active</small>{userTotalSellAmount} USDT </div>
      <div className="col-md-3"><small className='statsTitle'>My Active GBL</small>{userTotalTokenAmount} GBL </div>
      
        </div>
     
        </div>
        
   <br/>
        </div>
    );
}

export default Stats;
